<template src="./OvParticipationBar.html"/>
<script>
import electionApi from '@/api/ElectionApi'
import { config } from '@/config'

export default {
  components: {
  },
  props: {
    history: {
      type: String,
      default: null,
    },
    list: {
      type: String,
      default: null,
    },
    selectedTimestamp: {
      type: String,
      default: '',
    },
    szazalekok: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      config: config,
      hours: [],
      data: {
        participations: [],
      },
      participation: {},
      participations: {},
      selectedHour: this.selectedTimestamp,
    }
  },
  watch: {
    selectedTimestamp() {
      this.selectedHour = this.selectedTimestamp
    },
  },
  async mounted() {
    const data = await (await electionApi.getOrszagosReszvetel()).json()
    this.data.participations.push({
      key: '2024o',
      data: data.data.onk_valasztas.reszvetel,
    })
    config.participations.bars.filter(b => b.data).map(b => {
      this.data.participations.push({
        key: b.key,
        data: b.data,
      })
    })
    // this.setHours()
    //
    this.init()
    // this.loadData().then(data => {
    //    = data[0]
    //
    // }).catch(() => {
    //   this.data.nodata = true
    // }).finally(() => {
    //   this.$nextTick(() => {
    //     if (!this.data.nodata) {
    //       // this.init()
    //       // this.show()
    //     }
    //   })
    // })
  },
  computed: {
  },
  methods: {
    // async loadData() {
    //   return await (await electionApi.getOrszagosReszvetel()).json()
    // },
    init() {
      this.participations = this.data.participations.reduce(
          (prev, p) => (prev[p.key] = p.data.reduce((obj, data) => (obj[data.idopont] = data, obj), {}), prev),
          {},
      ) || {}
    },
    // show() {
    //   const message = {
    //     type: 'iframe-size',
    //     sourceAddress: window.location.href,
    //     height: document.body.scrollHeight + 20,
    //     width: document.body.scrollWidth,
    //   }
    //   // window.top refers to parent window
    //   this.$nextTick(() => window.parent.postMessage(message, '*'))
    // },
    setHours() {
      const hours = config.hours
      const now = new Date()
      const result = hours.map(h => {
        const diff = (new Date(config.participations.date + "T" + h + ":00")) - now
        return {
          hour: h,
          enabled: diff < 0,
          diff: diff < 0 ? diff : Infinity,
        }
      })
      const smallest = Math.min(...result.map(h => h.diff))
      const index = result.findIndex(e => e.diff === smallest)
      result[index].selected = true
      this.hours = result
      this.selectedHour = result[index].hour
    },
  },
}
</script>
<style lang="scss">
.dashboard-24 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0;
  @include l {
    margin: 0 0 10px 0;
  }
  &--live {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 7px 0;
    &__content {
      line-height: 1.2;
      margin: 0 0 2px 0;
      cursor: pointer;
    }
    &__title {
      font-weight: var(--bold);
      color: var(--color-text);
      font-size: .77rem;
      margin: 0 10px 0 0;
      display: inline;
    }
    &__btn {
      background:  var(--color-accent);
      color: var(--color-bg);
      border-radius: 2px;
      padding: 2px 7px;
      font-size: .77rem;
      font-weight: var(--bold);
      cursor: pointer;
      &:hover {
        opacity: .9;
      }
    }
    .live {
      width: max-content;
      display: inline-block;
      padding-right: 7px;
      .livetag {
        font-size: 0.66rem;
        text-transform: uppercase;
      }
    }
  }
  &--details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    //transform: translateY(-40px);
  }
  &__collapse {
    width: max-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    margin: 0 0 7px 0;
    color: var(--color-secondary);
    transition: var(--theme-text);
    transform: translateX(7px);
    i {
      font-size: 1.3rem;
      line-height: 1;
      transform: rotate(180deg);
    }
    .btn-active,
    .btn-inactive {
      font-weight: var(--bold);
      font-size: .88rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-active {
      display: block;
    }
    .btn-inactive {
      display: none;
    }
  }
  &--participation {
    display: grid;
    grid-template-areas: "a" "b";
    grid-template-columns: 1fr;
    grid-gap: 6px;
    width: 100%;
    margin: 0 auto 6px auto;
    position: relative;
    &:before {
      position: absolute;
      content: '* Automatikusan frissül';
      right: 0;
      bottom: -19px;
      font-size: .66rem;
    }
    &__bar {
      grid-area: b;
    }
    @include m {
      grid-template-areas: "a b b b";
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @include xl {
      width: 1120px;
    }
    & > div {
      background: var(--color-gray-mid-light);
      color: var(--color-text);
      border: 1px solid var(--color-gray-medium);
      transition: var(--theme), var(--theme-text), var(--theme-border) ;
      border-radius: 5px;
      padding: 10px 15px;
      @include l {
        padding: 15px 20px ;
      }
    }
  }
  &--results {
    display: grid;
    grid-template-areas: "a" "b" "c";
    grid-template-columns: 1fr;
    margin: 0 auto;
    grid-gap: 6px;
    width: 100%;
    @include l {
      grid-template-areas: "a a" "b c";
      grid-template-columns: 1fr 1fr;
    }
    @include xl {
      grid-template-areas: "a a b " "a a c";
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 1120px;
    }
    & > div {
      background: var(--color-gray-mid-light);
      color: var(--color-text);
      border: 1px solid var(--color-gray-medium);
      transition: var(--theme), var(--theme-text), var(--theme-border) ;
      border-radius: 5px;
    }
  }
  &--leaders {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    align-items: center;
    padding: 10px 15px;
    background: var(--color-gray-mid-light);
    color: var(--color-text);
    border: 1px solid var(--color-gray-medium);
    transition: var(--theme), var(--theme-text), var(--theme-border) ;
    border-radius: 5px;
    width: 100%;
    margin: 0 0 6px 0;
    cursor: pointer;
    position: relative;;
    @include m {
      padding: 15px 20px;
      grid-template-columns: 50px 1fr 50px;
      &:before {
        position: absolute;
        content: '* Automatikusan frissül';
        right: 0;
        bottom: -19px;
        font-size: .66rem;
      }
    }
    @include l {
      grid-template-columns: 70px 1fr 70px;
      padding: 15px 80px;
      grid-gap: 15px;
    }
    @include xl {
      grid-template-columns: 80px 1fr 80px;
      padding: 15px 120px;
    }
    .leader--a,
    .leader--b {
      display: none;
      @include m {
        display: block;
        width: 50px;
        height: 50px;
        //border-radius: 60px;
        //background: yellow;
        //border: 2px solid white;
      }
      @include l {
        width: 70px;
        height: 70px;
      }
      @include xl {
        width: 80px;
        height: 80px;
      }
    }
  }
  &--line {
    display: grid;
    // grid-template-columns: 3fr 2fr;
    & > span {
      display: grid;
      place-content: center;
      //color: var(--color-bg);
      color: white;
      transition: var(--theme-text);
      font-weight: var(--bold);
      height: 28px;
      border: 2px solid white;
      margin: 4px 0;
    }
    &--a {
      //background: var(--ov-fidesz);
      background: #f60;
    }
    &--b {
      //background: var(--ov-ellenzek);
      background: #5ba;
      border-left: none !important;
    }
  }
  &__list {
    margin: 10px 0 0 0;
    grid-gap: 0 !important;
    width: 100%;
    padding: 0 0 0 20px;
    li {
      display: inline-list-item;
      list-style-type: decimal !important;
      position: relative;
      &::marker {
        font-weight: var(--bold);
        font-size: .77rem;
        position: absolute;
        top: -11px
      }
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 0 0 7px 5px;
      &__org-name {
        font-weight: var(--bold);
      }
    }
    &__org-dot {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      background: var(--color-gray-medium);
      border: 2px solid var(--color-light);
      transition: var(--theme), var(--theme-border);
      border-radius: 2px;
      display: block;
    }
    &__org-name {
      display: flex;
      align-items: center;
      font-weight: var(--bold);
      font-size: .8rem;
    }
    &__name{
      font-size: .8rem !important;
      &:nth-child(2) {
        margin: 0 3px;
      }
    }
    p, span {
      font-size: .77rem;
      margin-top: 0;
    }
    li {
      margin: 0 0 5px 0;
      &:before {
        font-size: .77rem;
        font-weight: var(--bold);
        margin: 0 5px 0 0;
      }
    }
  }
  &--map {
    grid-area: a;
    position: relative;
    height: auto;
    @include l {
      height: 540px;
    }
    .dashboard-24__content {
      overflow: hidden;
    }
    &__info {
      margin: 10px 0 7px 0;
      display: flex;
      flex-direction: column;
      @include l {
        position: absolute;
        top: initial;
        bottom:10px;
        left: 40px;
        width: max-content !important;
      }
      @include xl {
        bottom:20px;
      }
      &__hint {
        display: flex;
        flex-direction: column;
        margin: 0 0 7px 0;
        @include m {
          max-width: 300px;
        }
        span {
          font-size:.77rem;
        }
      }
      &__gfx {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: max-content;
      }
      &__numbers {
        font-weight: var(--bold);
        font-size: .77rem;
        @include m {
          font-size: .88rem;
        }
      }
      &__bar {
        display: flex;
        flex-direction: column;
        margin: 0 7px;
        .info__bar {
          &--a {
            width: 90px;
            height: 15px;
            background: rgb(85,187,170);
            background: linear-gradient(90deg, rgba(255,102,0,0.3) 0%, rgba(255,102,0,1) 100%);
            margin: 0 0 3px 0;
            border: 2px solid var(--color-light);
            border-radius: 2px;
            @include m {
              width: 150px;
            }
          }
          &--b {
            width: 90px;
            height: 15px;
            background: rgb(85,187,170);
            background: linear-gradient(90deg, rgba(85,187,170,0.3) 0%, rgba(85,187,170,1) 100%);
            border: 2px solid var(--color-light);
            border-radius: 2px;
            @include m {
              width: 150px;
            }
          }
        }
      }
    }
  }
  &--map,
  &--seats,
  &--favourite {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    padding: 7px 20px 15px 20px;
  }
  &--favourite {
    grid-area:  c;
    height: auto;
    @include l {
      height: 267px;
    }
    .dashboard-24__content {
      margin: 20px 0 ;
      @include l {
        margin: 0 ;
      }
      //colors
      .org-1803 .dashboard-24__list__org-dot,
      .org-1063 .dashboard-24__list__org-dot {
        background: var(--ov-ellenzek);
      }
      .org-1798 .dashboard-24__list__org-dot,
      .org-641 .dashboard-24__list__org-dot,
      .org-1062 .dashboard-24__list__org-dot {
        background: var(--ov-fidesz);
      }
      .org-544 .dashboard-24__list__org-dot,
      .org-1750 .dashboard-24__list__org-dot {
        background: var(--ov-mkkp);
      }
      .org-1752 .dashboard-24__list__org-dot {
        background: var(--ov-mihazank);
      }
    }
  }
  &--seats {
    grid-area: b;
    height: auto;
    @include l {
      height: 267px;
    }
    div:nth-child(2) {
      width: 100%;
      display: grid;
      grid-template-columns: 80px 1fr;
      grid-gap: 10px;
      align-items: center;
      max-width: 400px;
      margin: 15px auto 10px auto;
      .ov-patko {
        width: 80px;
        max-width: 80px;
        @include m {
          width: 100px;
          max-width: 100px;
        }
      }
      .ov-patko-info span {
        font-size: .8rem;
        &:before {
          left: -22px;
        }
      }
      @include m {
        grid-template-columns: 100px 1fr;
      }
      @include l {
        margin: 0 auto;
      }
      .ov-patko {
        margin: 0;
      }
      .ov-patko-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .patko--info--1798:before,
      .patko--info--641:before,
      .patko--info--1061:before {
        background: var(--ov-fidesz);
      }
      .patko--info--1803:before,
      .patko--info--1062:before,
      .patko--info--1063:before {
        background: var(--ov-ellenzek);
      }
    }
    .dashboard-24__btn {
      margin: 10px 0 0 0;
      @include l {
        margin: 0;
      }
    }
  }
  &--barchart {
    display: flex;
    flex-direction: column;
    &__title,
    &__counter {
      display: flex;
      justify-content: space-between;
      align-items: center;}
    font-size: .7rem;
    @include s {
      font-size: .77rem;
    }
    span:last-child {
      text-align: right;
    }
  }
  &__title {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 0 10px 0;
    margin: 0 0 7px 0;
    border-bottom: 1px solid var(--color-gray-medium);
    span {
      font-weight: var(--bold);
    }
  }
  &__subtitle {
    font-size: .77rem;
    display: inline;
    font-weight: var(--regular);
    svg {
      fill: var(--color-accent);
      margin: -3px 3px 0 0;
      width: 12px;
    }
  }
  &__patko {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-gap: 30px;
    align-items: center;
    @include m {
      grid-template-columns: 110px 1fr;
    }
    &__info {
      display: flex;
      flex-direction: column;
      span {
        position: relative;
        font-size: .77rem;
        font-weight: var(--bold);
        margin: 0 0 3px 15px;
        &:before {
          content: '';
          position: absolute;
          width: 15px;
          height: 15px;
          top:3px;
          left: -22px;
          border: 1px solid white;
          border-radius: 3px;
        }
      }
    }
    #fidesz:before {
      background: var(--ov-fidesz);
    }
    #ellenzek:before {
      background: var(--ov-ellenzek);
    }
  }
  &__btn {
    width: 100%;
    white-space: nowrap;
    color: var(--color-text);
    border: 1px solid var(--color-text);
    transition: var(--theme), var(--theme-text), var(--theme-border);
    border-radius: 3px;
    padding: 5px 15px !important;
    font-size: .8rem !important;
    display: grid;
    place-content: center;
    background: var(--color-bg);
    &:hover {
      background: transparent;
    }
    @include m {
      width: max-content;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .election-2022-map {
      width: 100%;
      height: 100%;
    }
    .leaflet-zoom-animated .leaflet-interactive{
      //fill: yellow !important;
    }
  }
  &__topics {
    grid-area: a;
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      svg {
        height: 22px;
        margin: 5px 0 15px 0;
        cursor: pointer;
      }
    }
  }
  &__progress {
    //grid-area: b;
    font-size: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    svg {
      width: 12px;
      margin: -3px 3px 0 0;
    }
    &__bars {
      display: flex;
      flex-direction: column;
      grid-gap: 5px;
    }
    &__subtitle {
      color: var(--color-text);
      transition: var(--theme-text);
      font-weight: var(--regular);
      font-size: .77rem;
      margin: 5px 0;
      line-height: 1.5;
      display: block;
    }
    &__title {
      font-weight: var(--bold);
      font-size: .88rem;
      margin: 3px 0;
    }
    &__data {
      display: grid !important;
      place-content: center;
      border-radius: 3px;
      font-weight: var(--bold);
      font-size: .77rem;
    }
    &__line {
      display: grid !important;
      color: var(--color-text);
      transition: var(--theme-text);
      font-weight: var(--bold);
      background: transparent;
      // border: 2px solid white;
      margin: 4px 0;
      width: 100%;
      position: relative;
      &__bg {
        background: var(--color-gray-extra-light);
        border: 1px solid var(--color-gray-mid-light);
        border-radius: 3px;
        margin: 5px 0 0 0;
      }
    }
    &__data {
      //background: var(--color-accent-dark);
      background: #2fa553;
      color: white;
      width: 0;
      transition: width 1s;
      display: grid;
      place-content: center;
      min-width: 50px;
      border-radius: 2px 0 0 2px;
      font-size: .77rem;
      font-weight: var(--bold);
      height: 100%;
      &__bg {
        background: var(--color-gray-extra-light);
        width: 100%;
        border-radius: 3px;
        border: 1px solid var(--color-gray-mid-light);
        transition: var(--theme), var(--theme-border);
        height: 24px;
        margin: 3px 0 0 0;
      }
    }
    svg {
      fill: var(--color-accent);
      margin: 0 4px 0 0;
    }
    span {
      // display: inline;
    }

  }
  .election-2022-map.map-container {
    background: transparent;
    display: flex;
    flex-direction: column;
    position: initial !important;
    justify-content: center;
    @include l {
      display: block;
      position: relative !important;
    }
  }
  .dashboard--map.leaflet-container {
    position: relative !important;
    height: 200px;
    @include s {
      height: 250px;
    }
    @include m {
      height: 300px;
    }
    @include l {
      position: absolute !important;
      height: 400px;
      transform: translateX(-30px);
    }
    .leaflet-pane {
      width: 100% !important;
      transform: none !important;
      @include m {
        width: calc(100vw - 30px);
      }
    }
    svg {
      position: absolute;
    }
    .leaflet-overlay-pane {
      width: 100% !important;
      height: 170px !important;
      transform: none !important;
      position: relative !important;
      overflow: hidden !important;
      @include s {
        height: 250px !important;
      }
      @include m {
        height: 300px !important;
      }
      @include l {
        height: 350px !important;
      }
    }
    svg {
      height: 250px;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translateX(-50%) translateY(-50%)  !important;
      width: calc(100vw - 30px);
      @include s {
        height: 400px;
      }
      @include m {
        height: 500px;
      }
      @include l {
        width: 950px;
        transform: none !important;
        top: -20% !important;
        left: -25% !important;
      }
      @media screen and (min-width: 1000px) {
        width: 1300px;
        left: -20% !important;
      }
      @include xl {
        width: 900px;
      }
    }
  }
  .dashboard--map--bp {
    position: relative;
    height: 150px;
    width: 150px;
    transform: translateY(-20px);
    @include m {
      transform: translateY(-10px);
    }
    &.leaflet-container {
      position: relative !important;
      width: 100%;
      height: 200px;
      max-height: 200px;
      @include l {
        position: absolute !important;
        width: 200px;
        bottom: 10px;
        right: 0;
        transform: none;
      }
      @include xl {
        width: 200px;
        bottom: 30px;
      }
      .leaflet-map-pane {
        width: 100%;
      }
    }
  }
}
#dashboard-24-size  {
  display: none;
}
#dashboard-24-size +.dashboard-24__collapse + .dashboard-collapse {
  display:none;
}
#dashboard-24-size:checked  + .dashboard-24__collapse {
  .btn-active {
    display: none;
  }
  .btn-inactive {
    display: flex;
  }
  i {
    transform: rotate(0deg);
  }
}
#dashboard-24-size:checked + .dashboard-24__collapse + div {
  display: none;
}
</style>
