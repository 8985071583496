<template>
  <div v-if="epEuResult && epNationalResults">
    <div v-if="epEuResult.time" class="ep24-result-header">
      <strong>Állapot:</strong> {{ epEuResult.status }}
      -
      <strong>Adatközlés dátuma:</strong>
      {{ displayUpdateDate }}
    </div>
    <div class="ep24-chart">
      <div class="piechart-container">
        <div
            class="piechart"
            :style="{ 'background-image': `conic-gradient(from -90deg, ${colorStyle}, transparent 0 180deg)` }"
        >
          <div class="inner">
            <div class="inner2">
              <div class="info">
                {{ epEuResult.seatsTotal }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="legend-container">
        <span v-for="distribution in epEuResult.groupDistribution" :key="`legend-${distribution.groupInfo.acronym}`" class="legend">
          <span class="legend-color-box" :style="{'background-color': `${distribution.groupInfo.color}`}" />
          <span class="legend-title">
            {{ distribution.groupInfo.longName.charAt(0).toUpperCase() + distribution.groupInfo.longName.slice(1) }}
            ({{ distribution.groupInfo.acronym }})
            -
            {{ distribution.seatsTotal }}
          </span>
        </span>
        <span v-if="epEuResult.seatsTotal !== sumSeats" class="legend">
          <span class="legend-color-box" :style="{'background-color': `salmon`}" />
          <span class="legend-title">
            Még ki nem osztott mandátumok - {{ epEuResult.seatsTotal - sumSeats }}
          </span>
        </span>
      </div>
    </div>
    <div class="ep24-mandates">
      <div class="ep24-mandates--a">
        <div v-for="result in epNationalResultsEven" :key="'mandate-accrodion-' + result.id">
          <accordion>
            <template #title>
              <div class="accordion__title">
                <span class="ep24-mandates__sum">
                  {{ result.countryName }}: {{ result.seatsTotal }} mandátum
                </span>
              </div>
              <div class="accordion__barchart">
                <div
                  v-for="(group, index) in result.groupSummary.groupDistribution"
                  :key="index"
                  :style="{ backgroundColor: group.groupInfo.color, width: getPercentage(group.seatsTotal, result.seatsTotal) + '%!important' }"
                  class="progress-bar-segment"
                >
                  <span v-if="!(group.seatsTotal < 2 && $device.mobile)">
                    {{ group.seatsTotal }}
                  </span>
                </div>
                <div
                  v-if="result.seatsTotal > sumSeatsGroup(result.groupSummary.groupDistribution)"
                  :style="{ backgroundColor: 'salmon', width: getPercentage(result.seatsTotal - sumSeatsGroup(result.groupSummary.groupDistribution), result.seatsTotal) + '%' }"
                  class="progress-bar-segment"
                >
                  {{ result.seatsTotal - sumSeatsGroup(result.groupSummary.groupDistribution) }}
                </div>
              </div>
            </template>
            <template #content>
              <div class="accordion__content">
                <ep-progress-bar
                    :group-distribution="result.groupSummary.groupDistribution"
                    :seats-total="result.seatsTotal"
                    :turnout-percent="result.turnout.percent"
                    class="ep24-mandates__bar"
                />
              </div>
            </template>
          </accordion>
        </div>
      </div>
      <div class="ep24-mandates--b">
        <div v-for="result in epNationalResultsOdd" :key="result.id">
          <accordion>
            <template #title>
              <div class="accordion__title">
                <span class="ep24-mandates__sum">
                  {{ result.countryName }}: {{ result.seatsTotal }} mandátum
                </span>
              </div>
              <div class="accordion__barchart">
                <div
                    v-for="(group, index) in result.groupSummary.groupDistribution"
                    :key="index"
                    :style="{ backgroundColor: group.groupInfo.color, width: getPercentage(group.seatsTotal, result.seatsTotal) + '%!important' }"
                    class="progress-bar-segment"
                >
                  <span v-if="!(group.seatsTotal < 2 && $device.mobile)">
                    {{ group.seatsTotal }}
                  </span>
                </div>
                <div
                    v-if="result.seatsTotal > sumSeatsGroup(result.groupSummary.groupDistribution)"
                    :style="{ backgroundColor: 'salmon', width: getPercentage(result.seatsTotal - sumSeatsGroup(result.groupSummary.groupDistribution), result.seatsTotal) + '%' }"
                    class="progress-bar-segment"
                >
                  {{ result.seatsTotal - sumSeatsGroup(result.groupSummary.groupDistribution) }}
                </div>
              </div>
            </template>
            <template #content>
              <div class="accordion__content">
                <ep-progress-bar
                    :group-distribution="result.groupSummary.groupDistribution"
                    :seats-total="result.seatsTotal"
                    :turnout-percent="result.turnout.percent"
                    class="ep24-mandates__bar"
                />
              </div>
            </template>
          </accordion>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sizeReporter from '@/mixins/sizeReporter'
import Accordion from "@/components/sub-components/Accordion.vue";
import EpProgressBar from "@/components/sub-components/EpProgressBar.vue";
import electionApi from "@/api/ElectionApi";

export default {
  components: { Accordion, EpProgressBar },
  mixins: [sizeReporter],
  data() {
    return {
      epEuResult: null,
      epNationalResults: null,
    }
  },
  computed: {
    colorStyle() {
      let seatsStart = 0
      const colors = []
      this.epEuResult.groupDistribution.forEach((item) => {
        colors.push({
          color: item.groupInfo.color,
          end: (seatsStart + item.seatsTotal) * 180 / this.epEuResult.seatsTotal,
        })
        seatsStart += item.seatsTotal
      })
      if (seatsStart < this.epEuResult.seatsTotal) {
        colors.push({
          color: 'salmon',
          end: 180,
        })
      }
      return colors.map(item => `${item.color} 0 ${item.end}deg`).join(', ')
    },
    sumSeats() {
      return this.epEuResult.groupDistribution.reduce((accumulator, obj) => accumulator + obj.seatsTotal, 0)
    },
    epNationalResultsEven() {
      return this.epNationalResults.filter((_, index) => index % 2 === 0)
    },
    epNationalResultsOdd() {
      return this.epNationalResults.filter((_, index) => index % 2 !== 0)
    },
    displayUpdateDate() {
      // Step 1: Parse the input date string to a Date object
      const inputDate = new Date(this.epEuResult.time)

      // Step 2: Format the date components
      const year = inputDate.getFullYear()
      const monthNames = ["január", "február", "március", "április", "május", "június", "július", "augusztus", "szeptember", "október", "november", "december"]
      const month = monthNames[inputDate.getMonth()]
      const day = inputDate.getDate()

      // Step 3: Adjust the time to the desired timezone and format it
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'Europe/Budapest', // Set to your desired timezone
      };
      const timeFormatter = new Intl.DateTimeFormat('hu-HU', options)
      const time = timeFormatter.format(inputDate)

      // Step 4: Combine the formatted date and time components
      return `${year} ${month} ${day}. – ${time}`
    },
  },
  async mounted() {
    const epEuResult = await (await electionApi.getEpEuResult()).json()
    this.epEuResult = epEuResult.data.euResult[0]
    const epNationalResults = await (await electionApi.getEpNationalResults()).json()
    this.epNationalResults = epNationalResults.data.nationalResults.sort((a, b) => {
      return a.seatsTotal < b.seatsTotal ? 1 : -1
    })
  },
  methods: {
    getPercentage(seats, sumSeats) {
      return (seats / sumSeats) * 100
    },
    sumSeatsGroup(group) {
      return group.reduce((accumulator, obj) => accumulator + obj.seatsTotal, 0)
    },
  },
}
</script>
<style lang="scss">
.ep24 {
  &-chart {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    align-items: center;
    overflow: hidden;
    background: var(--color-list);
    border: 1px solid var(--color-gray-mid-light);
    transition: var(--theme), var(--theme-border);
    border-radius: 7px;
    padding: 20px;
    margin: 0 0 15px 0;
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
      padding: 30px;
      grid-gap: 60px;
      margin: 0 0 30px 0;
    }
  }
  &-mandates {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px 30px;
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: 1200px) {
      grid-gap: 15px 60px;
    }
    &__bar {
    }
    &__parties {
      display: flex;
      flex-direction: column;
      &__participation {
        color: var(--color-text);
        transition: var(--theme-text);
        font-size: .77rem;
        font-weight: var(--regular);
      }
      &__results {
        gap: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      &__seat {
        width: 15px;
        height: 15px;
        border-radius: 2px;
      }
      &__title {
        font-size: .77rem;
        font-weight: var(--bold);
        margin: 10px 0 3px 0;
        color: var(--color-text);
        transition: var(--theme-text);
      }
    }
    &__sum {
      font-size: .77rem;
      font-weight: var(--bold);
      @media screen and (min-width: 1200px) {
        font-size: .9rem;

      }
    }
    &--a,
    &--b {
      display: grid;
      gap: 15px;
      align-content: start;
    }
    .accordion {
      &__barchart {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 20px);
        @media screen and (min-width: 768px) {
          width: calc(100% - 40px);
          bottom: 15px;
        }
      }
      &__head {
        position: relative;
        padding: 10px 10px 30px 10px;
        @media screen and (min-width: 768px) {
          padding: 10px 20px 40px 20px;
        }
      }
      &__icon {
        transform: translateY(-2px);
        i {
          font-size: 1.3rem;
          font-weight: var(--bold);
        }
      }
      &__title {
        margin: 0 0 7px 0;
      }
    }
  }
  &-result-header {
    font-size: smaller;
    margin-bottom: 1em;
  }
}

.piechart-container {
  width: 100%;
  height: 0;
  padding-top: 50%;
  position: relative;
  overflow: hidden;
  .piechart {
    grid-area: 1 / 1 / 2 / 2;
    width: 100%;
    height: 200%;
    border-radius: 50%;
    transition: background-image 0.3s ease;
    position: absolute;
    top:0;
    left:0;

    .inner {
      width: 50%;
      height: 50%;
      border-radius: 50%;
      background-color: var(--color-list);
      transition: var(--theme);
      transform: translate(50%, 50%);
    }

    .inner2 {
      width: 50%;
      height: 50%;
      border-radius: 50%;
      background-image: conic-gradient(
              from -90deg,
              lightgray 0 180deg,
              transparent 0 180deg
      );
      transform: translate(50%, 50%);
    }

    .info {
      text-align: center;
      font-size: 0.88rem;
      font-weight: var(--bold);
      padding: 18% 0 0 0;
      color: var(--black);
    }
  }

}
.legend {
  margin: 0 0 7px 0;
  &-color-box {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 7px;
    border-radius: 2px;
    border: 1px solid var(--color-gray-mid-light);
  }
  &-container {
    // grid-area: 1 / 2 / 2 / 3;
    font-size: .77rem;
    font-weight: var(--bold);
    grid-gap: 60px;
    max-height: 200px;
    overflow: scroll;
    @media screen and (min-width: 500px) {
      max-height: none;
      overflow: initial;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  &-title {
    font-weight: var(--bold);
    font-size: .66rem;
    &:first-letter {
      text-transform: capitalize;

    }
    @media screen and (min-width: 500px) {
      font-size: .77rem;
    }
  }
}
.progress-bar {
  display: flex;
  gap: 2px;
  height: 20px;
  width: 100%;
  &-segment {
    text-align: center;
    border-radius: 3px;
    color: var(--white);
    font-size: .66rem;
    font-weight: var(--max);
    height: 20px;
    display: grid;
    place-content: center;
  }
}
</style>
