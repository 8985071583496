<!--suppress JSNonASCIINames -->
<template>
  <div class="frame__container">
    <ov-more v-if="!inactive" />
    <div class="ov__container ov2024__container">
      <div ref="mapContainer" class="maplibre-container __telex__container" :class="{ inactive: inactive }">
        <div v-if="nodata"  class="nodata">
          <p>{{ locale['Nodata.Message'] }}</p>
          <p><button @click="init">{{ locale['Nodata.Button'] }}</button></p>
        </div>
        <ov-list-result
            @slug-change="(newSlug) => { this.setRoute(this.$props.basePath, newSlug) }"
            v-if="!inactive && ovListVisibility && initialAdatVerzio && state.electionProperties && state.electionProperties.name === 'onkormanyzati'"
            :base-path="basePath"
            :isMapBig="isBig"
            @keyup=""
            :szavazokor="selectedSzavazokor"
            :slug="slug"
            :selectedLayer="selectedLayer"
            :adatVerzio="adatVerzio"
            :selectedTimestamp="idopontok[selectedTimestamp]"
            :data="getMapDataReszvetel()"
            :megye="state.megye"
            :telepules="state.telepules"
        />
        <ep-country-result
          v-if="state.electionProperties && state.electionProperties.name === 'ep'"
          :base-path="basePath"
          :isMapBig="isBig"
          @selectCountryCode="(countryCode) => { this.setSelectedMemberState(countryCode)}"/>
        <div ref="mapSizer" class="map-sizer" v-bind:class="{big: isBig}">
          <election-toggle v-if="!inactive && !isBig" :election="election" @onChanged="(newElection) => switchElection(newElection)" />
          <div ref="map" class="map"></div>
          <div v-if="state.electionProperties && state.electionProperties.name === 'onkormanyzati'">
            <div class="tooltip" v-if="hoveredState.szavazokorok">
              <span>{{ hoveredState.szavazokorok.eredetiCim }}</span>
              <span> - </span>
              <span>{{ hoveredState.szavazokorok.szavazokorok.join(', ') }}</span>
              <span>. szavazókör{{ hoveredState.szavazokorok.szavazokorok.length > 1 ? 'ök' : '' }}</span>
            </div>
            <div class="tooltip" v-if="(hoveredState.megyek || hoveredState.telepulesek || hoveredState.megyeijogu) && !hoveredState.szavazokorok">
              <div v-if="selectedLayer === 'reszvetel'">Részvételi arány {{ idopontok[selectedTimestamp]}}-kor: {{ hoveredState.telepulesek ? (hoveredState.telepulesek.reszvetelSzazalekok ? JSON.parse(hoveredState.telepulesek.reszvetelSzazalekok)[selectedTimestamp] * 100 : 0).toFixed(2) : (hoveredState.megyek.reszvetelSzazalekok ? JSON.parse(hoveredState.megyek.reszvetelSzazalekok)[selectedTimestamp] * 100 : 0).toFixed(2) }}%</div>
              <div v-if="selectedLayer === 'eredmenyek' && !hoveredState.megyeijogu && hoveredState.megyek && hoveredState.megyek.kozgyulesElso">{{ hoveredState.megyek.kozgyulesElso }}: {{ hoveredState.megyek.kozgyulesMandatumok }} mandátum (összesen: {{ hoveredState.megyek.kozgyulesLetszam }})</div>
              <div v-if="selectedLayer === 'eredmenyek' && hoveredState.megyeijogu && hoveredState.megyeijogu.kozgyulesElso">{{ hoveredState.megyeijogu.kozgyulesElso }}: {{ hoveredState.megyeijogu.kozgyulesMandatumok }} mandátum (összesen: {{ hoveredState.megyeijogu.kozgyulesLetszam }})</div>
              <div v-if="selectedLayer === 'eredmenyek' && hoveredState.telepulesek && hoveredState.telepulesek.polgarmesterNev">{{ hoveredState.telepulesek.polgarmesterNev }}: {{ hoveredState.telepulesek.polgarmesterSzazalek }} ({{ hoveredState.telepulesek.polgarmesterPart }})</div>
              <span>{{ hoveredState.megyek ? hoveredState.megyek.NAME : this.state.megye.nev }}</span>
              <span v-if="hoveredState.telepulesek || hoveredState.megyeijogu"> - </span>
              <span v-if="hoveredState.telepulesek || hoveredState.megyeijogu">{{ hoveredState.telepulesek ? hoveredState.telepulesek.NAME : hoveredState.megyeijogu.NAME }}</span>
            </div>
            <div class="maplibregl-ctrl maplibregl-ctrl-group" v-else></div>
          </div>
        </div>
        <div class="maplibregl-ctrl__custom_controls">
          <div ref="controlHome" class="maplibregl-ctrl maplibregl-ctrl-group maplibregl-ctrl-home">
            <button v-if="getHomeButtonState" rel="homeButton" type="button" title="Magyarország" aria-label="Magyarország">
              <span class="maplibregl-ctrl-icon magyarorszag" aria-hidden="true" @click="resetView" />
            </button>
          </div>
          <div ref="controlMegye" class="maplibregl-ctrl maplibregl-ctrl-group maplibregl-ctrl-back">
            <button v-if="state.telepules" rel="backButton" type="button" :title="state.megye.nev" :aria-label="state.megye.nev">
              <span class="maplibregl-ctrl-icon" :class="`${state.megye.slug}_megye`" aria-hidden="true" @click="setRoute($props.basePath, state.megye.slug)"/>
            </button>
          </div>
          <div ref="controlZoom" class="maplibregl-ctrl maplibregl-ctrl-group">
            <button rel="zoomButton" class="maplibregl-ctrl-zoom" type="button" title="Teljes képernyő" aria-label="Zoom" @click="handleZoomButton">
              <span class="maplibregl-ctrl-icon" aria-hidden="true" />
            </button>
          </div>
          <div ref="controlSearch" class="map-home">
            <div class="maplibregl-ctrl maplibregl-ctrl-group maplibregl-ctrl-search" v-bind:class="{ expanded: showSearch }">
              <button rel="searchButton" type="button" title="Keresés" aria-label="Keresés"><span class="maplibregl-ctrl-icon search" aria-hidden="true" @click="showSearch = !showSearch"/></button>
              <v-autocomplete
                  ref="acRef"
                  v-if="showSearch"
                  :items="telepulesek"
                  :auto-select-one-item="false"
                  :get-label="getTelepulesLabel"
                  :component-item="ovTelepulesItem"
                  :min-len="0"
                  :wait="0"
                  :input-attrs="{ placeholder: 'Budapest', class: 'search-input' }"
                  @input="setTelepules"
                  @update-items="updateTelepulesList"
                  @focus="updateTelepulesList"
              />
            </div>
          </div>
          <div ref="controlLayer" class="maplibregl-ctrl maplibregl-ctrl-group maplibregl-ctrl-layer">
            <div class="layers" v-if="adatVerzio && adatVerzio.adatTipus !== 'torzsadatok'" v-on:mouseleave="layerSwitcherHovered = false" v-on:mouseenter="layerSwitcherHovered = layerSwitcherOpen === null">
              <template v-for="layer in validLayers">
                <button class="layerSwitcher" :class="[layer.id, layer.id === selectedLayer || (layerSwitcherHovered && layerSwitcherOpen === null) || layerSwitcherOpen === true ? 'layer' : '']" @touchstart="touchLayer(layer)" @click="clickLayer(layer)" :id="layer.id">
                  <span class="gradient"></span>
                  <span class="layer__title">
                    <template v-if="layerSwitcherHovered || layerSwitcherOpen">
                      {{ layer.name }}
                    </template>
                    <template v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z"/></svg>
                      &nbsp;Rétegek
                    </template>
                  </span>
                  <span class="layer__background"></span>
                </button>
              </template>
            </div>
          </div>
          <div ref="controlTimestamp" class="maplibregl-ctrl maplibregl-ctrl-group maplibregl-ctrl-timestamp" :style="selectedLayer !== 'reszvetel' ? 'display: none' : ''">
            <div>{{ idopontok[selectedTimestamp] }}</div>
            <input id="opacity-slider" type="range" min="0" :max="maxTimestamp" step="1" v-model="selectedTimestamp">
          </div>
        </div>
        <powered-by v-if="!inactive" />
      </div>
      <embed-me v-if="!inactive" :path="embedPath" />
    </div>
  </div>
</template>
<script>
// import eventBus from '@/app/event-bus'

import sizeReporter from '@/mixins/sizeReporter'
import routing from '@/mixins/routing'
import partyColor from '@/mixins/partyColor'
import lightMode from "@/components/LightMode";
// import darkMode from "@/components/DarkMode";
import Layers from './Layers'
import electionApi from '@/api/ElectionApi'
import EmbedMe from '@/components/sub-components/EmbedMe'
import OvTelepulesItem from './sub-components/OvTelepulesItem'
import VAutocomplete from 'v-autocomplete'
import OvListResult from './sub-components/OvListResult'
import ElectionToggle from './sub-components/ElectionToggle'
import EpCountryResult from './EpCountryResult.vue';
import PoweredBy from '@/components/sub-components/PoweredBy'
import OvMore from '@/components/sub-components/OvMore'
import { config } from '@/config'

let staticSources = []
let onkormanyzatiMapControls = []

const maplibregl = require('maplibre-gl')

class Map extends maplibregl.Map {

  _cameraForBoxAndBearing(p0, p1, bearing, options ) {
    if (this.transform._edgeInsets) {
      this.transform._edgeInsets.left = 0
      this.transform._edgeInsets.right = 0
      this.transform._edgeInsets.top = 0
      this.transform._edgeInsets.bottom = 0
    }
    return super._cameraForBoxAndBearing( p0, p1, bearing, options );
  }
}

const HUNGARY_MAX_BOUNDS = [
        [16, 45.65],
        [23, 48.65],
      ]

const ELECTION_MODE_DATA_EP = {
    name: 'ep',
    bounds: [
        [-12.17,34.43],
        [34.88,70.21],
    ],
  }

const ELECTION_MODE_DATA = [
  {
    name: 'onkormanyzati',
    bounds: HUNGARY_MAX_BOUNDS,
  },
  ELECTION_MODE_DATA_EP,
]

class CustomControl {
  constructor(container, onClick) {
    this._container = container
    this._onClick = onClick
  }
  onAdd(map) {
    this._map = map
    this._container.addEventListener('contextmenu', e => e.preventDefault())
    if (this._onClick) {
      this._container.addEventListener('click', this._onClick)
    }
    return this._container
  }
  onRemove() {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }
}

export default {
  mixins: [
    sizeReporter,
    routing,
    partyColor,
  ],
  components: {
    PoweredBy,
    OvListResult,
    EpCountryResult,
    VAutocomplete,
    OvTelepulesItem,
    ElectionToggle,
    EmbedMe,
    OvMore,
  },
  props: {
    basePath: {
      type: String,
      default: '/onkormanyzati/terkep',
    },
    epBasePath: {
      type: String,
      default: '/ep/terkep',
    },
    onkormanyzatiBasePath: {
      type: String,
      default: '/onkormanyzati/terkep',
    },
    theme: {
      type: String,
      default: 'light',
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    inactive: {
      type: Boolean,
      default: false,
    },
    election: {
      type: String,
      default: ELECTION_MODE_DATA_EP.name,
    },
  },
  data() {
    return {
      electionChangeInProgress: false,
      allLayers: {
        jeloltek: {
          name: 'Jelöltek',
          id: 'jeloltek',
        },
        reszvetel: {
          name: 'Részvétel',
          id: 'reszvetel',
        },
        eredmenyek: {
          name: 'Eredmények',
          id: 'eredmenyek',
        },
      },
      versionRefreshId: null,
      initialAdatVerzio: null,
      initialLayer: null,
      adatVerzio: null,
      selectedTimestamp: 0,
      maxTimestamp: 0,
      idopontok: ["07:00", "09:00", "11:00", "13:00", "15:00", "17:00", "18:30", "19:00"],
      megyekReszvetelDataLoaded: false,
      telepulesekReszvetelDataLoaded: [],
      megyeiJoguVarosokReszvetelDataLoaded: false,
      megyekEredmenyDataLoaded: false,
      telepulesekEredmenyDataLoaded: [],
      megyeiJoguEredmenyDataLoaded: false,
      layerSwitcherOpen: null,
      layerSwitcherHovered: false,
      selectedLayer: 'jeloltek',
      nodata: false,
      guid: this.generateUUID(),
      isBig: false,
      showSearch: true,
      searchValue: '',
      telepulesek: [],
      ovTelepulesItem: OvTelepulesItem,
      sidebarInfo: null,
      geolocationOptions: {
        fitBoundsOptions: {
          duration: 1000,
          maxZoom: 10,
        },
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: false,
        showUserLocation: false,
      },
      hoveredState: {
        megyek: null,
        telepulesek: null,
        szavazokorok: null,
        megyeijogu: null,
      },
      selectedSzavazokor: null,
      state: {
        zoom: 0,
        megye: null,
        telepules: null,
        center: [19.5, 47],
        bounds: null,
        animation: 1,
        opacity: 0.4,
        // preferredColorScheme: "auto",
        // colorScheme: null,
      },
      isVisible: null,
      ovListVisibility: true,
      easingNames: [
        'easeInQuart',
        'easeInOutQuart',
        'easeInOutBounce',
        'easeOutBounce',
        'easeInOutElastic',
        'easeInBack',
      ],
      easing: x => x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2,
      idealBounds: HUNGARY_MAX_BOUNDS,
      locale: {
        'AttributionControl.ToggleAttribution': 'Toggle attribution',
        'AttributionControl.MapFeedback': 'Map feedback',
        'FullscreenControl.Enter': 'Enter fullscreen',
        'FullscreenControl.Exit': 'Exit fullscreen',
        'GeolocateControl.FindMyLocation': 'Helyzetmeghatározás',
        'GeolocateControl.LocationNotAvailable': 'A helyzetmeghatározás nem működik',
        'LogoControl.Title': 'MapLibre logo',
        'NavigationControl.ResetBearing': 'Reset bearing to north',
        'NavigationControl.ZoomIn': 'Nagyítás',
        'NavigationControl.ZoomOut': 'Kicsinyítés',
        'ScaleControl.Feet': 'ft',
        'ScaleControl.Meters': 'm',
        'ScaleControl.Kilometers': 'km',
        'ScaleControl.Miles': 'mi',
        'ScaleControl.NauticalMiles': 'nm',
        'TerrainControl.Enable': 'Enable terrain',
        'TerrainControl.Disable': 'Disable terrain',
        'CooperativeGesturesHandler.WindowsHelpText': 'Use Ctrl + scroll to zoom the map',
        'CooperativeGesturesHandler.MacHelpText': 'Use ⌘ + scroll to zoom the map',
        'CooperativeGesturesHandler.MobileHelpText': 'Use two fingers to move the map',
        'Nodata.Message': 'Hiba a térkép betöltése közben!',
        'Nodata.Button': 'Újra',
      },
      megyeReszvetelMin: [],
      megyeReszvetelMax: [],
      telepulesReszvetelMin: Array.from({length: 20}, () => []),
      telepulesReszvetelMax: Array.from({length: 20}, () => []),
    }
  },
  async beforeMount() {
    switch (this.$props.election) {
      case 'onkormanyzati':
        const route = this.$route.path
        this.initialAdatVerzio = (await (await electionApi.getVerzio()).json()).data.onk_verzio
        if (route.includes(this.$props.basePath)) {
          const pathParts = route
              .replace(this.$props.basePath, '')
              .replace(/^\/|\/$/g, '')
              .split('/')
          const lastPart = pathParts.slice().pop()
          if (this.validLayerKeys.includes(lastPart)) {
            this.initialLayer = lastPart
          } else {
            this.initialLayer = this.initialAdatVerzio.adatTipus
          }
        } else {
          this.initialLayer = this.initialAdatVerzio.adatTipus
        }
        electionApi.setVersion(this.initialAdatVerzio.datum)
        break;
      case 'ep':
        this.initialAdatVerzio = {}
        break
    }
  },
  mounted() {
    // this.$store.commit('maps/addMap', {
    //   guid: this.guid,
    //   loaded: false,
    //   bounds: this.getBounds,
    //   toggle: this.toggle,
    //   isVisible: this.isShown,
    // })
    // this.searchInput = this.$refs.searchInput
    // this.searchInput.addEventListener('keyup', this.search)
    // eventBus.$emit('map-added', { register: this.registerController })
    this.setElectionProperties(this.$props.election);
    this.init()
    this.$nextTick(() => {
      this.ro = new ResizeObserver(() => {
        if (this.map) {
          const bounds = this.map.getBounds()
          this.map.setMaxBounds(null)
          this.map.fitBounds(this.idealBounds, {
            linear: true,
            duration: 0,
          });
          requestAnimationFrame(() => {
            this.map.setMaxBounds(this.map.getBounds());
            this.map.fitBounds(bounds, {
              linear: true,
              duration: 0,
            });
          });
        }
      });
      this.ro.observe(this.$refs.mapSizer);
    });
    document.addEventListener('keypress', this.toggleOvListVisibility);
  },
  beforeUnmount() {
    document.removeEventListener('keypress', this.toggleOvListVisibility)
    if (this.ro) {
      this.ro.unobserve(this.$refs.mapSizer);
      this.ro.disconnect();
      this.ro = null;
    }
  },
  beforeDestroy() {
    this.isBig = false;
    clearInterval(this.versionRefreshId);
    this.$emit('map-size-changed', false);
  },
  computed: {
    validLayers() {
      let validLayers = []
      for (let key of Object.keys(this.allLayers)) {
        validLayers.push(this.allLayers[key]);
        if (this.adatVerzio && key === this.adatVerzio.adatTipus) {
          break;
        }
      }
      return validLayers
    },
    validLayerKeys() {
      let validLayers = []
      for (let key of Object.keys(this.allLayers)) {
        validLayers.push(key);
        if (this.adatVerzio && key === this.adatVerzio.adatTipus) {
          break;
        }
      }
      return validLayers
    },
    layers() {
      const keys = Object.keys(this.allLayers)
      const startIdx = keys.indexOf(this.layerSwitcherHovered ? this.nextLayer : this.selectedLayer)
      const reorderedKeys = keys.slice(startIdx).concat(keys.slice(0, startIdx))
      return reorderedKeys.map(key => this.allLayers[key])
    },
    nextLayer() {
      let keys = Object.keys(this.allLayers)
      let index = keys.indexOf(this.selectedLayer)
      return index >= keys.length - 1 ? keys[0] : keys[index + 1]
    },
    embedPath() {
      let path = '/onkormanyzati/terkep'
      if (this.state.megye?.slug) {
        path += '/' + this.state.megye.slug
      }

      if (this.state.telepules?.slug) {
        path += '/' + this.state.telepules.slug
      }

      return path
    },
    getTheme() {
      return 'light'
      // return ['light', 'dark'].includes(this.theme) ? this.theme : 'auto'
    },
    getHomeButtonState() {
      function boundsEqual(map) {
        const maxBounds = map.getMaxBounds();
        const currentBounds = map.getBounds();
        return maxBounds && currentBounds
            && Math.abs(maxBounds.getEast() - currentBounds.getEast()) < 0.1
            && Math.abs(maxBounds.getNorth() - currentBounds.getNorth()) < 0.1
            && Math.abs(maxBounds.getSouth() - currentBounds.getSouth()) < 0.1
            && Math.abs(maxBounds.getWest() - currentBounds.getWest()) < 0.1
      }
      return this.state.megye || this.state.telepules || (this.map && !boundsEqual(this.map))
    },
  },
  watch: {
    async election(newValue, oldValue) {
      this.state.megye = null
      this.state.telepules = null
      // this.resetView()
      this.setElectionProperties(newValue)

      // set europ geojson
      const europeGeojsonPath = config.map.geojsonsPathPrefix
          + (newValue === 'ep' ? '/assets/geojsons/eu.geojson' : '/assets/geojsons/europa.geojson');
      const europaData = await fetch(europeGeojsonPath).then(res => res.json())
      const source = staticSources.find(source => source.name === 'europe')
      source.data = europaData
      await this.map.getSource('europe').setData(europaData)

      if (newValue === 'onkormanyzati') {
        // Magyarország kivágasa, hogy az EU-s ne takarjon rá
        this.map.setFilter('europe-layer', ['!=', "NAME", "Hungary"])

        //beállítjuk a controlokat
        this.removeOnkormanyzatiControls()
        this.addOnkormanyzatiControls()

        /// Az országba bezoomolni
        this.map.setMaxBounds(null)
        this.idealBounds = HUNGARY_MAX_BOUNDS
        this.map.fitBounds(this.idealBounds, {
          linear: true,
          duration: 0,
        });
        requestAnimationFrame(() => {
          this.map.setMaxBounds(this.map.getBounds());
        });
      }

      if (newValue === 'ep') {
        this.removeOnkormanyzatiControls()
        // A megyelayer-re rátakarni az europe layerrel:
        this.map.setFilter('europe-layer', null)

        // Az kontinensbe kizoomolni
        this.map.setMaxBounds(null)
        this.idealBounds = ELECTION_MODE_DATA_EP.bounds //HUNGARY_MAX_BOUNDS
        this.map.fitBounds(this.idealBounds, {
          linear: true,
          duration: 0,
        });
        requestAnimationFrame(() => {
          this.map.setMaxBounds(this.map.getBounds());
        });
      }

      this.electionChangeInProgress = false
    },
    "state.megye"(newValue, oldValue) {
      if (this.electionChangeInProgress) {
        console.log('prevent state.megye watch')
        oldValue && this.map.setFeatureState({source: 'megyek', id: oldValue.id}, {selected: false});
        return
      }
      oldValue && this.map.setFeatureState({source: 'megyek', id: oldValue.id}, {selected: false});
      newValue && this.map.setFeatureState({source: 'megyek', id: newValue.id}, {selected: true});
      this.map.setFilter('szavazokorok-circle', ['==', ['get', 'megyeSlug'], newValue ? newValue.slug : ""])
    },
    "state.telepules"(newValue, oldValue) {
      if (this.electionChangeInProgress) {
        console.log('prevent state.telepules watch')
        oldValue && this.map.setFeatureState({source: 'telepulesek', id: oldValue.id}, {selected: false});
        return
      }
      oldValue && this.map.setFeatureState({source: 'telepulesek', id: oldValue.id}, {selected: false});
      newValue && this.map.setFeatureState({source: 'telepulesek', id: newValue.id}, {selected: true});
      this.map.setFilter('szavazokorok', ['==', ['get', 'telepulesSlug'], newValue
        ? this.state.megye.slug + '/' + newValue.slug
        : "-1",
      ])
    },
    isBig(newValue, oldValue) {
      if (this.electionChangeInProgress) {
        console.log('prevent isBig watch')
        return
      }
      this.$emit('map-size-changed', newValue, oldValue);
    },
    adatVerzio(newValue, oldValue) {
      if (this.electionChangeInProgress) {
        console.log('prevent adatVerzio watch')
        return
      }
      if (!oldValue || newValue.datum > oldValue.datum) {
        electionApi.setVersion(newValue.datum)
        if (newValue.adatTipus === 'reszvetel') {
          this.megyeiJoguVarosokReszvetelDataLoaded = false;
          this.megyekReszvetelDataLoaded = false;
          this.telepulesekReszvetelDataLoaded = []
          this.loadReszvetel()
          if (!oldValue || oldValue.adatTipus === 'torzsadatok') {
            // this.selectedLayer = 'reszvetel'
          }
        }
        if (newValue.adatTipus === 'eredmenyek') {
          this.megyekEredmenyDataLoaded = false;
          this.telepulesekEredmenyDataLoaded = []
          this.loadEredmeny()
          if (!oldValue || oldValue.adatTipus === 'reszvetel') {
            // this.selectedLayer = 'eredmenyek'
          }
        }
      }
    },
    selectedLayer(newValue, oldValue) {
      if (this.electionChangeInProgress) {
        console.log('prevent selectedLayer watch')
        return
      }
      if (oldValue !== newValue) {
        switch (newValue) {
          case 'reszvetel': this.loadReszvetel(); break;
          case 'eredmeny': this.loadEredmeny(); break;
        }
        // this.setRoute(this.$props.basePath, this.slug + '/' + newValue)
        this.switchLayer()
      }
    },
    slug(newValue, oldValue) {
      if (this.electionChangeInProgress) {
        console.log('prevent slug watch')
        return
      }
      requestAnimationFrame(() => {
        this.selectArea(newValue, oldValue === null ? 0 : null, oldValue)
      })
    },
    $route(route) {
      if (this.electionChangeInProgress) {
        console.log('prevent $route watch')
        return
      }
      this.setSlugFromRoute(route)
    },
    theme() {
      if (this.electionChangeInProgress) {
        console.log('prevent theme watch')
        return
      }
      this.$nextTick(function() {
        if (this.isShown()) {
          this.setTheme()
        }
      })
    },
    selectedTimestamp() {
      if (this.electionChangeInProgress) {
        console.log('prevent selectedTimestamp watch')
        return
      }
      this.switchLayer()
    },
  },
  methods: {
    switchElection(newType) {
      this.electionChangeInProgress = true
      this.setRoute(newType === 'ep' ? this.epBasePath : this.onkormanyzatiBasePath, '')
    },
    setElectionProperties(electionId) {
      const electionProperties = ELECTION_MODE_DATA.find(mode => mode.name === electionId);
      if (electionProperties) {
        this.idealBounds = electionProperties.bounds;
        this.state.electionProperties = electionProperties
      }
    },
    clickLayer(layer) {
      if (this.layerSwitcherOpen === null) {
        if (this.nextLayer !== layer.id) {
          this.layerSwitcherHovered = false
        }
        this.selectedLayer = this.selectedLayer === layer.id ? this.nextLayer : layer.id
      }
    },
    touchLayer(layer) {
      this.layerSwitcherOpen = !this.layerSwitcherOpen
      this.selectedLayer = layer.id
    },
    toggleOvListVisibility(e) {
      if(e.key === 'l') {
        this.ovListVisibility = !this.ovListVisibility;
      }
    },
    init() {
      this.nodata = false
      this.toggle(false)
      this.toggle(true)
      this.sendSizeMessage()
    },
    setTheme() {
      const mode = this.getTheme === 'dark' ? lightMode : lightMode
      if (this.map) {
        this.map.setStyle(mode)
      }
    },
    // registerController(controller) {
    //   this.controller = controller
    // },
    isShown() {
      return this.map && !this.map._removed
    },
    getBounds() {
      return this.$refs.mapContainer.getBoundingClientRect()
    },
    handleZoomButton() {
      this.isBig = !this.isBig
      document.body.classList.remove('scroll-down')
      document.body.classList.remove('scroll-up')
    },
    async updateTelepulesList(value) {
      if (value && value.length > 0 && value !== this.searchValue) {
        this.searchValue = value
        const result = await (await electionApi.getTelepulesek(value)).json()
        if (result && result.data && result.data.telepules && Array.isArray(result.data.telepules) && result.data.telepules.length > 0) {
          this.telepulesek = result.data.telepules
        } else {
          this.telepulesek = []
        }
      } else {
        this.telepulesek = []
      }
    },
    getTelepulesLabel(item) {
      return item && item.nev
    },
    setTelepules(item) {
      if (item) {
        this.setRoute(this.$props.basePath, item.slug)
      }
      return false
    },
    getTorzsadatFill(layer, property) {
      return property === 'fill-color'
          ? (layer === 'telepules-layer' ? '#fff' : layer === 'megyei-jogu-layer' ? '#C7CDD2' : 'transparent')
          : 0.3
    },
    getReszvetelFill(layer, property) {
      return property === "fill-color" ? [
        'interpolate',
        ['linear'],
        [
          "at", Number(this.selectedTimestamp),
          ["coalesce",
            ["get", "reszvetelAranyok"],
            ["literal", [0, 0, 0, 0, 0, 0, 0, 0]],
          ],
        ],
        0, '#fff',
        1, '#0f0',
      ] : [
        'interpolate',
        ['linear'],
        ["at", Number(this.selectedTimestamp), ["coalesce", ["get", "reszvetelSzazalekok"], ["literal", [0, 0, 0, 0, 0, 0, 0, 0]]]],
        0, 0.1,
        1, 0.6,
      ]
    },
    getEredmenyFill(layer, property) {
      return property === "fill-color" ? ["coalesce",
        ["get", ['megye-layer', "megyei-jogu-layer"].includes(layer) ? "kozgyulesColor" : "polgarmesterColor"],
          "white",
      ] : [
        'interpolate',
        ['linear'],
        ["coalesce",
          ["get", ['megye-layer', "megyei-jogu-layer"].includes(layer) ? "kozgyulesOpacity" : "polgarmesterOpacity"],
          0,
        ],
        0, 0.1,
        1, 0.7,
      ]
    },
    setLayerFill(styleFn) {
      ['megye-layer', 'telepules-layer', "megyei-jogu-layer"].map(layer =>
          ['fill-color', 'fill-opacity'].map(property =>
              this.map.setPaintProperty(layer, property, styleFn(layer, property))),
      )
    },
    switchLayer() {
      switch(this.selectedLayer) {
        case 'reszvetel':
          this.map.setPaintProperty("megyei-jogu-layer-shadow", "fill-opacity", 0)
          this.map.setPaintProperty("megyei-jogu-layer", "fill-opacity", 0)
          this.setLayerFill(this.getReszvetelFill);
          break
        case 'eredmenyek':
          this.map.setPaintProperty("megyei-jogu-layer-shadow", "fill-opacity", 1)
          this.setLayerFill(this.getEredmenyFill);
          break
        default:
          this.map.setPaintProperty("megyei-jogu-layer-shadow", "fill-opacity", 0)
          this.map.setPaintProperty("megyei-jogu-layer", "fill-opacity", .4)
          this.setLayerFill(this.getTorzsadatFill)
      }
    },
    async toggle(show) {
      if (show && !this.isShown()) {
        await this.createMap()
        this.registerEvents()
      } else if (!show && this.isShown()) {
        this.map.remove()
      }
    },
    async loadMegyekReszvetel() {
      const response = electionApi.getMegyekReszvetelTerkep()
      const reszvetelData = await (await response).json();
      const megyek = this.map.getSource('megyek')._data;
      reszvetelData.data.onk_valasztas.teruletek.map(megye => {
        const megyeIndex = megyek.features.findIndex(sourceMegye =>
            sourceMegye.properties.slug === (megye.slug === 'csongrad' ? 'csongrad-csanad' : megye.slug),
        )
        megyek.features[megyeIndex].properties.reszvetelAranyok = megye.reszvetelAranyok
        megyek.features[megyeIndex].properties.reszvetelSzazalekok = megye.reszvetelSzazalekok
      })
      this.map.getSource('megyek').setData(megyek)
      const reszvetelSzazalekok = megyek.features[0].properties.reszvetelSzazalekok
      this.selectedTimestamp = reszvetelSzazalekok.length - reszvetelSzazalekok.reverse().findIndex(_ => _ > 0) - 1
      this.maxTimestamp = this.selectedTimestamp
      this.megyekReszvetelDataLoaded = true
    },
    async loadKeruletekReszvetel(megyeSlug) {
      const reszvetelData = this.telepulesekReszvetelDataLoaded[megyeSlug]
          || await (await electionApi.getKeruletekReszvetelTerkep(megyeSlug)).json()
      const telepulesek = this.map.getSource('telepulesek')._data;
      reszvetelData.data.onk_valasztas.teruletek[0].keruletek.map(kerulet => {
        const teruletIndex = telepulesek.features.findIndex(sourceKerulet =>
            sourceKerulet.properties.slug === kerulet.slug,
        )
        telepulesek.features[teruletIndex].properties.reszvetelAranyok = kerulet.reszvetelAranyok
        telepulesek.features[teruletIndex].properties.reszvetelSzazalekok = kerulet.reszvetelSzazalekok
      })
      this.map.getSource('telepulesek').setData(telepulesek)
      this.telepulesekReszvetelDataLoaded[megyeSlug] = reszvetelData
    },
    async loadMegyeiJoguVarosokReszvetel() {
      const response = electionApi.getKeruletekReszvetelTerkep(null, true)
      const reszvetelData = await (await response).json();
      const telepulesek = this.map.getSource('telepulesek')._data;
      reszvetelData.data.onk_valasztas.teruletek.map(terulet =>
        terulet.keruletek.map(kerulet => {
          const teruletIndex = telepulesek.features.findIndex(sourceKerulet =>
            sourceKerulet.properties.slug === kerulet.slug,
          )
          telepulesek.features[teruletIndex].properties.orszagosReszvetelAranyok = kerulet.orszagosReszvetelAranyok
          telepulesek.features[teruletIndex].properties.reszvetelAranyok = kerulet.reszvetelAranyok
          telepulesek.features[teruletIndex].properties.reszvetelSzazalekok = kerulet.reszvetelSzazalekok
        }),
      )
      this.map.getSource('telepulesek').setData(telepulesek)
      this.megyeiJoguVarosokReszvetelDataLoaded = true
    },
    loadReszvetel() {
      if (!this.megyekReszvetelDataLoaded) {
        this.loadMegyekReszvetel()
      }
      if (!this.megyeiJoguVarosokReszvetelDataLoaded) {
        // this.loadMegyeiJoguVarosokReszvetel()
      }
      if (this.state.megye) {
        this.loadKeruletekReszvetel(this.state.megye.slug)
      }
    },
    async loadMegyekEredmeny() {
      this.megyekEredmenyDataLoaded = true
      const response = electionApi.getMegyekEredmenyTerkep()
      const eredmenyData = await (await response).json();
      const megyek = this.map.getSource('megyek')._data;
      eredmenyData.data.onk_valasztas.teruletek.map(megye => {
        const megyeIndex = megyek.features.findIndex(sourceMegye =>
            sourceMegye.properties.slug === (megye.slug === 'csongrad' ? 'csongrad-csanad' : megye.slug),
        )
        megyek.features[megyeIndex].properties.kozgyulesColor = this.getPartyColor(megye.listak[0].jeloloCsoport)
        megyek.features[megyeIndex].properties.kozgyulesOpacity = (megye.listak[0].mandatumokSzama - megye.listak[1].mandatumokSzama) / megye.listak[0].mandatumokSzama
        megyek.features[megyeIndex].properties.kozgyulesMandatumok = megye.listak[0].mandatumokSzama
        megyek.features[megyeIndex].properties.kozgyulesLetszam = megye.kozgyulesLetszam
        megyek.features[megyeIndex].properties.kozgyulesElso = megye.listak[0].nev
        megyek.features[megyeIndex].properties.listak = megye.listak
      })
      this.map.getSource('megyek').setData(megyek)
    },
    async loadMegyeiJoguVarosokEredmeny() {
      this.megyeiJoguEredmenyDataLoaded = true
      const response = electionApi.getMegyeiJoguKozgyulesEredmenyTerkep()
      const eredmenyData = await (await response).json();
      const telepulesek = this.map.getSource('megyeijogu')._data;
      eredmenyData.data.onk_valasztas.teruletek.map(terulet =>
          terulet.keruletek.map(kerulet => {
            const teruletIndex = telepulesek.features.findIndex(sourceKerulet =>
                sourceKerulet.properties.slug === kerulet.slug,
            )
            telepulesek.features[teruletIndex].properties.kozgyulesColor = this.getPartyColor(kerulet.listak[0].jeloloCsoport)
            telepulesek.features[teruletIndex].properties.kozgyulesOpacity = (kerulet.listak[0].osszesMandatum - kerulet.listak[1].osszesMandatum) / kerulet.listak[0].osszesMandatum
            telepulesek.features[teruletIndex].properties.kozgyulesMandatumok = kerulet.listak[0].osszesMandatum
            telepulesek.features[teruletIndex].properties.kozgyulesLetszam = kerulet.kozgyulesLetszam
            telepulesek.features[teruletIndex].properties.kozgyulesElso = kerulet.listak[0].nev
          }),
      )
      this.map.getSource('megyeijogu').setData(telepulesek)
    },
    async loadKeruletekEredmeny(megyeSlug) {
      const eredmenyData = this.telepulesekEredmenyDataLoaded[megyeSlug]
          || await (await electionApi.getKeruletekEredmenyTerkep(megyeSlug)).json();
      const telepulesek = this.map.getSource('telepulesek')._data;
      eredmenyData.data.onk_valasztas.teruletek[0].keruletek.map(kerulet => {
        if (!kerulet.polgarmesterJeloltek.length) {
          return
        }
        const teruletIndex = telepulesek.features.findIndex(sourceKerulet =>
            sourceKerulet.properties.slug === kerulet.slug,
        )
        const elso = kerulet.polgarmesterJeloltek[0] ? kerulet.polgarmesterJeloltek[0].szavazat || 0 : 0
        const masodik = kerulet.polgarmesterJeloltek[1] ? kerulet.polgarmesterJeloltek[1].szavazat || 0 : 0
        telepulesek.features[teruletIndex].properties.polgarmesterColor = this.getPartyColor(kerulet.polgarmesterJeloltek[0].jeloloCsoport)
        telepulesek.features[teruletIndex].properties.polgarmesterOpacity = elso ? (elso - masodik) / elso : 0
        telepulesek.features[teruletIndex].properties.polgarmesterNev = kerulet.polgarmesterJeloltek[0].nev
        telepulesek.features[teruletIndex].properties.polgarmesterSzazalek = kerulet.polgarmesterJeloltek[0].szazalek
        telepulesek.features[teruletIndex].properties.polgarmesterPart = kerulet.polgarmesterJeloltek[0].jeloloCsoport.nev
      })

      this.map.getSource('telepulesek').setData(telepulesek)
      this.telepulesekEredmenyDataLoaded[megyeSlug] = eredmenyData
    },
    loadEredmeny() {
      if (!this.megyekEredmenyDataLoaded) {
        this.loadMegyekEredmeny()
      }
      if (!this.megyeiJoguEredmenyDataLoaded) {
        this.loadMegyeiJoguVarosokEredmeny()
      }
      if (this.state.megye) {
        this.loadKeruletekEredmeny(this.state.megye.slug)
      }
    },
    async createMap() {
      this.$refs.map.style.height = '100%'
      // this.layerSwitcher = new LayerSwitcher(this)
      const europeGeojsonPath = config.map.geojsonsPathPrefix + (this.$props.election === 'ep' ? '/assets/geojsons/eu.geojson' : '/assets/geojsons/europa.geojson');
      const sources = [
        {name: 'megyek', type: "geojson", url: config.map.geojsonsPathPrefix + '/assets/geojsons/megyek.geojson'},
        {name: 'telepulesek', type: "geojson", url: config.map.geojsonsPathPrefix + '/assets/geojsons/telepulesek/empty.geojson'},
        {name: 'szavazokorok', type: "geojson", url: config.map.geojsonsPathPrefix + '/assets/geojsons/szavazokorok/empty.geojson'},
        {name: 'europe', type: "geojson", url: europeGeojsonPath},
        {name: 'megyeijogu', type: "geojson", url: config.map.geojsonsPathPrefix + '/assets/geojsons/megyeijogu.geojson'},
      ];
      await Promise.all(
          sources.map(async({name, type, url}) => {
            if (!staticSources.find(source => source.name === name)) {
              staticSources.push({name, type, data: await fetch(url).then(res => res.json())})
            }
          }),
      ).catch(() => {
        this.nodata = true
        staticSources = []
      })
      if (this.nodata) return
      this.map = new Map({
        container: this.$refs.map,
        center: this.state.center,
        bounds: this.state.bounds,
        zoom: this.state.zoom,
        maxBounds: this.idealBounds,
        locale: this.locale,
        maxTileCacheSize: 0,
        interactive: !this.inactive,
      })
      const attributionControl = this.map._controls.filter(c => typeof c._toggleAttribution === 'function')[0]
      attributionControl && attributionControl._toggleAttribution()
      this.map.once('style.load', this.setTheme)
      staticSources.map(({name, type, data}) => this.map.addSource(name, {type, data}))
      const szavazokorMarker = await this.map.loadImage('/assets/images/szavazokor.png')
      const szavazokorMarkerSelected = await this.map.loadImage('/assets/images/szavazokor_selected.png')
      this.map.addImage('szavazokor-marker', szavazokorMarker.data)
      this.map.addImage('szavazokor-marker-selected', szavazokorMarkerSelected.data)

      if (this.inactive) {
        document.documentElement.classList.add('inactive')
        document.body.classList.add('inactive')
        return
      }
      this.setMapControl()
    },
    setMapControl() {
      if (!this.$props.mobile && !config.embed.hideFullScreen) {
        // this.map.addControl(this.zoomButton)
        this.map.addControl(new CustomControl(this.$refs.controlZoom), 'top-right')
      }
      this.geolocateButton = new maplibregl.GeolocateControl(this.geolocationOptions)
      this.navigationButton = new maplibregl.NavigationControl({
        showCompass: false,
        showZoom: !this.$device.mobile,
      })
      this.map.addControl(this.navigationButton, 'top-right')
      this.map.addControl(this.geolocateButton, 'top-right')
      if (this.state.electionProperties && this.state.electionProperties.name === 'onkormanyzati' ) {
        this.removeOnkormanyzatiControls()
        this.addOnkormanyzatiControls()
      }
    },
    addOnkormanyzatiControls() {
      const controlHome = new CustomControl(this.$refs.controlHome)
      onkormanyzatiMapControls.push(controlHome)
      this.map.addControl(controlHome, 'top-right')

      const controlMegye = new CustomControl(this.$refs.controlMegye)
      onkormanyzatiMapControls.push(controlMegye)
      this.map.addControl(controlMegye, 'top-right')

      const controlSearch = new CustomControl(this.$refs.controlSearch)
      onkormanyzatiMapControls.push(controlSearch)
      this.map.addControl(controlSearch, 'top-left')

      const controlLayer = new CustomControl(this.$refs.controlLayer)
      onkormanyzatiMapControls.push(controlLayer)
      this.map.addControl(controlLayer, 'bottom-left')

      const controlTimestamp = new CustomControl(this.$refs.controlTimestamp)
      onkormanyzatiMapControls.push(controlTimestamp)
      this.map.addControl(controlTimestamp, 'bottom-left')
    },
    removeOnkormanyzatiControls() {
      onkormanyzatiMapControls.forEach((mapControl) => {
        this.map.removeControl(mapControl)
      })
      onkormanyzatiMapControls = []
    },
    szavazokorokHoverHandler(feature) {
      const szavazokorok = feature.properties.szavazokorok ? JSON.parse(feature.properties.szavazokorok) : null
      return { szavazokorok }
    },
    handleHoverState(layer, layerHoverHandler) {
      return (e) => {
        if (e.features.length > 0 && !isNaN(e.features[0].id)
            && (!this.hoveredState[layer] || !this.hoveredState[layer].id || e.features[0].id !== this.hoveredState[layer].id))
        {
          if (this.hoveredState[layer] && !isNaN(this.hoveredState[layer].id)) {
            this.map.setFeatureState(
                {source: layer, id: this.hoveredState[layer].id},
                {hover: false},
            );
          }
          this.hoveredState[layer] = {
            id: e.features[0].id,
            ...e.features[0].properties,
            ...(layerHoverHandler ? layerHoverHandler(e.features[0]) : null),
          }
          this.map.setFeatureState(
              {source: layer, id: this.hoveredState[layer].id},
              {hover: true},
          );
        }
      }
    },
    handleMouseMove(layer) {
      return () => {
        if (this.hoveredState[layer] && !isNaN(this.hoveredState[layer].id)) {
          this.map.setFeatureState(
              {source: layer, id: this.hoveredState[layer].id},
              {hover: false},
          );
        }
        this.hoveredState[layer] = null;
      }
    },
    findFeatureBySlug(sourceName, slug) {
      const features = staticSources.find(source => source.name === sourceName).data.features
      const feature = features.find(feature => feature.properties.slug === slug)
      return feature ? {
        id: feature.properties.id,
        slug: feature.properties.slug,
        nev: feature.properties.NAME,
        boundingBox: feature.properties.boundingBox,
      } : null
    },
    findFeatureBySlugInData(sourceData, slug) {
      const features = sourceData.features
      const feature = features.find(feature => feature.properties.slug === slug)
      return feature ? {
        id: feature.properties.id,
        slug: feature.properties.slug,
        nev: feature.properties.NAME,
        boundingBox: feature.properties.boundingBox,
      } : null
    },
    findFeaturePropertyByCountryCode(sourceName, slug, propertyName) {
      const features = staticSources.find(source => source.name === sourceName).data.features
      const feature = features.find(feature => {
        return feature.properties[propertyName]?.toLowerCase() === slug
      })
      return feature ? {
        id: feature.properties.id,
        slug: feature.properties.slug,
        nev: feature.properties.NAME,
        boundingBox: feature.properties.boundingBox,
      } : null
    },
    setSlugFromRoute(route) {
      if (route.path.includes(this.$props.basePath)) {
        const pathParts = route.path
            .replace(this.$props.basePath, '')
            .replace(/^\/|\/$/g, '')
            .split('/')
        const lastPart = pathParts.slice().pop()
        if (this.validLayerKeys.includes(lastPart)) {
          this.selectedLayer = lastPart
          this.slug = pathParts.slice(0, -1).join('/')
        } else {
          this.slug = pathParts.slice(-2).join('/')
        }
      }
    },
    getPadding() {
      return window.innerWidth > 767
          ? this.state.telepules
              ? { right: 420, left: 20, top: 100, bottom: 20 }
              : this.state.megye
                  ? { right: 220, left: 20, top: 100, bottom: 20 }
                  : { right: 20, left: 20, top: 20, bottom: 20 }
          : { right: 20, left: 20, top: 20, bottom: 20 }
    },
    async selectArea(slug, duration, oldSlug) {
      if (this.state.electionProperties === ELECTION_MODE_DATA_EP) {
        // TODO check how to get rid of this inefficiency
        const memberState = this.findFeaturePropertyByCountryCode('europe', slug, 'EU');
        const boundingBox = memberState ? memberState.boundingBox : ELECTION_MODE_DATA_EP.bounds;
        this.fitBounds(boundingBox, this.getPadding(), duration)
      } else {
        let [megye, telepules] = slug.split('/')
        let [oldMegye, oldTelepules] = oldSlug ? oldSlug.split('/') : []
        let telepulesData = null;
        if (!telepules) {
          const szavazokorData = await fetch(`${config.map.geojsonsPathPrefix}/assets/geojsons/szavazokorok/empty.geojson`).then(res => res.json())
          this.map.getSource('szavazokorok').setData(szavazokorData)
        }
        if (megye === "csongrad") {
          megye = "csongrad-csanad"
        }
        if (megye) {
          if (megye !== oldMegye) {
            telepulesData = await fetch(`${config.map.geojsonsPathPrefix}/assets/geojsons/telepulesek/${megye}.geojson`).then(res => res.json())
            this.map.getSource('telepulesek').setData(telepulesData)
          } else {
            telepulesData = this.map.getSource('telepulesek')._data
          }
        }
        if (megye && telepules) {
          const szavazokorData = await fetch(`${config.map.geojsonsPathPrefix}/assets/geojsons/szavazokorok/${megye}_${telepules}.geojson`).then(res => res.json())
          this.map.getSource('szavazokorok').setData(szavazokorData)
        }
        this.state.megye = megye
            ? this.findFeatureBySlug('megyek', megye)
            : null
        this.state.telepules = this.state.megye
            ? this.findFeatureBySlugInData(telepulesData, telepules)
            : null
        this.filterLayers(this.state.megye, this.state.telepules)
        this.selectedSzavazokor = null
        this.map.setLayoutProperty('szavazokorok', 'icon-image', "szavazokor-marker")
        if (this.adatVerzio && this.adatVerzio.adatTipus !== "torzsadatok") {
          this.loadReszvetel()
        }
        if (this.adatVerzio && this.adatVerzio.adatTipus === "eredmenyek") {
          this.loadEredmeny()
        }
        this.$refs.acRef.setItems([])
        this.$refs.acRef.searchText = ''
        if (this.state.telepules) {
          this.fitBounds(this.state.telepules.boundingBox, this.getPadding(), duration)
          this.$refs.acRef.searchText = this.state.telepules.nev
        } else if (this.state.megye) {
          this.$refs.acRef.searchText = this.state.megye.nev.replace(' vármegye', '')
          this.fitBounds(this.state.megye.boundingBox, this.getPadding(), duration)
        } else if (this.state.bounds) {
          this.resetLayers()
          this.fitBounds(this.map.getMaxBounds(), this.getPadding(), this.geolocationOptions.fitBoundsOptions.duration)
        }
      }
    },
    addData() {
      if (!this.map.getSource('megyek')) {
        staticSources.map(({name, type, data}) => this.map.addSource(name, {type, data}))
      }
      if (!this.map.getLayer('megye-layer')) {
        const theme = this.getTheme
        Layers.layers.map(layer => {
          if (this.map.getSource(layer.source)) {
            this.map.addLayer(Layers.updateLayerColor(layer, theme, this.state.electionProperties), layer.before ? layer.before[theme] : null)
          }
        })
      }
    },
    registerEvents() {
      if (this.geolocateButton) {
        this.geolocateButton.on('geolocate', this.geolocate)
      }
      this.map.on('styledata', this.addData)
      this.map.on('load', async() => {
        // this.$store.commit('maps/setLoaded', this.guid)
        this.adatVerzio = this.initialAdatVerzio
        this.versionRefreshId = setInterval(async() => {
          this.adatVerzio = (await (await electionApi.getVerzio()).json()).data.onk_verzio
        }, 60000);
        this.selectedLayer = this.initialLayer
        this.setSlugFromRoute(this.$route)
        this.map.setMaxBounds(null)
        this.map.fitBounds(this.idealBounds, {
          linear: true,
          duration: 0,
        });
        requestAnimationFrame(() => {
          this.map.setMaxBounds(this.map.getBounds());
        });

        if (this.inactive) {
          return
        }

        if (!this.$props.mobile) {
          this.map.on('mousemove', 'europe-layer', this.handleHoverState('europe'));
          this.map.on('mouseleave', 'europe-layer', this.handleMouseMove('europe'));
          this.map.on('mousemove', 'megye-layer', this.handleHoverState('megyek'));
          this.map.on('mouseleave', 'megye-layer', this.handleMouseMove('megyek'));
          this.map.on('mousemove', 'telepules-layer', this.handleHoverState('telepulesek'));
          this.map.on('mouseleave', 'telepules-layer', this.handleMouseMove('telepulesek'));
          this.map.on('mousemove', 'megyei-jogu-layer', this.handleHoverState('megyeijogu'));
          this.map.on('mouseleave', 'megyei-jogu-layer', this.handleMouseMove('megyeijogu'));
          this.map.on('mousemove', 'szavazokorok', this.handleHoverState('szavazokorok', this.szavazokorokHoverHandler));
          this.map.on('mouseleave', 'szavazokorok', this.handleMouseMove('szavazokorok'));
        }
        this.map.on('moveend', this.updateState)
        this.map.on('zoomend', this.updateState)
        // this.map.on('contextmenu', this.resetView)
        this.map.on('click', this.handleClick)
        this.map.on('click', 'szavazokorok', (e) => {
          this.selectedSzavazokor = {
            id: e.features[0].id,
            ...e.features[0].properties,
            ...this.szavazokorokHoverHandler(e.features[0]),
          }
          this.map.setLayoutProperty('szavazokorok', 'icon-image', [
            "match",
            ["id"], e.features[0].id,
            "szavazokor-marker-selected",
            "szavazokor-marker",
          ])
          this.map.flyTo({
            center: e.features[0].geometry.coordinates,
            padding: this.getPadding(),
            curve: 1.8,
            easing: this.easing,
          }, { synthetic: true });
        })
        this.map.on('mouseenter', 'szavazokorok', () => {
          this.map.getCanvas().style.cursor = 'pointer'
        })
        this.map.on('mouseleave', 'szavazokorok', () => {
          this.map.getCanvas().style.cursor = ''
        })
      })
      this.map.on('remove', () => {
        // this.$store.commit('maps/clearLoaded', this.guid)
      })
      this.sendSizeMessage()
    },
    updateState() {
      this.state = {
        ...this.state,
        bounds: this.map.getBounds(),
        zoom: this.map.getZoom(),
      }
    },
    resetView() {
      if (this.slug.replace(/\/$/, '') !== '') {
        this.setRoute(this.$props.basePath, '')
      } else {
        this.resetLayers()
        this.fitBounds(this.map.getMaxBounds(), this.getPadding(), this.geolocationOptions.fitBoundsOptions.duration)
      }
    },
    geolocate(e) {
      this.state.megye = null
      this.state.telepules = null
      this.resetLayers()
      window.setTimeout(() => {
        const coords = this.map.project([e.coords.longitude, e.coords.latitude])
        const megye = this.map.queryRenderedFeatures(coords).find(f => f.layer.id === 'megye-layer')
        if (megye) {
          this.state.megye = {
            id: megye.properties.id,
            slug: megye.properties.slug,
            nev: megye.properties.NAME,
            boundingBox: JSON.parse(megye.properties.boundingBox),
          }
          this.filterLayers(this.state.megye)
          window.setTimeout(() => {
            const coords = this.map.project([e.coords.longitude, e.coords.latitude])
            const telepules = this.map.queryRenderedFeatures(coords).find(f => f.layer.id === 'telepules-layer')
            this.state.telepules = {
              id: telepules.properties.id,
              slug: telepules.properties.slug,
              nev: telepules.properties.NAME,
              boundingBox: JSON.parse(telepules.properties.boundingBox),
            }
            this.filterLayers(this.state.megye, this.state.telepules)
            this.setRoute(this.$props.basePath, this.state.megye.slug + '/' + this.state.telepules.slug)
          }, 100)
        }
      }, 1000)
    },
    deselectMemberStates() {
      this.state.memberState = null;
      // Hotfix: ha ez az if nincs itt, akkor örökre az ep mellékletben maradunk
      if (!this.electionChangeInProgress && this.$route.fullPath.startsWith(this.epBasePath)) {
        this.setRoute(this.$props.basePath, '');
      }
      this.fitBounds(ELECTION_MODE_DATA_EP.bounds, this.getPadding(), this.geolocationOptions.fitBoundsOptions.duration);
    },
    setSelectedMemberState(countryCode) {
      if (!countryCode) {
        this.deselectMemberStates();
      } else {
        const memberState = this.findFeaturePropertyByCountryCode('europe', countryCode, 'EU');
        this.state.memberState = memberState;
        this.setRoute(this.$props.basePath, countryCode);
      }
    },
    handleClick(e) {
      this.layerSwitcherOpen = null
      if (this.state.electionProperties === ELECTION_MODE_DATA_EP) {
        const countryFeature = this.map.queryRenderedFeatures(e.point).find(f => f.layer.id === 'europe-layer')
        if (countryFeature) {
          const countryCode = countryFeature.properties.EU.toLowerCase();
          if (countryCode === 'hu') {
            const megye = this.map.queryRenderedFeatures(e.point).find(f => f.layer.id === 'megye-layer')
            const telepules = this.map.queryRenderedFeatures(e.point).find(f => f.layer.id === 'telepules-layer')
          }
          this.setSelectedMemberState(countryCode);
        }
      } else {
        this.ovListVisibility = true;
        if (this.map.getCanvas().style.cursor !== 'pointer') {
          const features = this.getFeatures(e)
          const masikTelepules = !this.state.telepules || this.state.telepules.slug !== features.telepules
          if (this.selectedSzavazokor) {
            this.selectedSzavazokor = null
            this.map.setLayoutProperty('szavazokorok', 'icon-image', "szavazokor-marker")
            if (!masikTelepules) {
              return
            }
          }
          const masikMegye = !this.state.megye || this.state.megye.slug !== features.megye
          const megyeiJoguVaros = Layers.megyeiJoguVarosok.includes(features.telepules)
          const telepulesSwitchAllowed = (masikTelepules && !masikMegye) || (masikMegye && megyeiJoguVaros)
          const slug = (features.megye || '') + (telepulesSwitchAllowed ? '/' + features.telepules : '')
          if (slug !== this.slug) {
            this.setRoute(this.$props.basePath, slug)
          }
        }
      }
    },
    generateUUID() {
      let d = new Date().getTime()
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
    },
    resetLayers(e) {
      if (!e || !e.synthetic) {
        if (this.state.megye === null) {
          this.map.setFilter('megye-layer', null)
          this.map.setFilter('megyei-jogu-layer', null)
          this.map.setFilter('megyei-jogu-layer-shadow', null)
          this.map.setFilter('telepules-layer', ['all', ['==', 'id', '-1']])
          this.map.setFilter('telepules-outline', ['all', ['==', 'id', '-1']])
        } else if (this.state.telepules === null) {
          this.map.setFilter('megyei-jogu-layer', ['all', ['!=', 'megyeId', this.state.megye.id]])
          this.map.setFilter('megyei-jogu-layer-shadow', ['all', ['!=', 'megyeId', this.state.megye.id]])
          this.map.setFilter('telepules-layer', ['all', ['==', 'megyeId', this.state.megye.id]])
          this.map.setFilter('telepules-outline', ['all', ['==', 'megyeId', this.state.megye.id]])
        }
      }
    },
    filterLayers(megye) {
      if (megye) {
        this.map.setFilter('telepules-outline', ['all', ['==', 'megyeId', megye.id]])
        this.map.setFilter('telepules-layer', ['all', ['==', 'megyeId', megye.id]])
        this.map.setFilter('megyei-jogu-layer', ['all', ['!=', 'megyeId', megye.id]])
        this.map.setFilter('megyei-jogu-layer-shadow', ['all', ['!=', 'megyeId', megye.id]])
        this.map.setFilter('megye-layer', ['all', ['!=', 'id', megye.id]])
      }
    },
    getFeatures(e) {
      const features = this.map.queryRenderedFeatures(e.point)
      const megye = features.find(f => f.layer.id === 'megye-layer')
      const telepules = features.find(f => f.layer.id === 'megyei-jogu-layer' || f.layer.id === 'telepules-layer')
      return telepules
          ? { megye: telepules.properties.megyeSlug, telepules: telepules.properties.slug }
          : megye ? { megye: megye.properties.slug } : {}
    },
    fitBounds(bounds, padding, duration = null) {
      duration = duration !== null ? { duration } : {}
      this.map.fitBounds(
          bounds,
          { padding, curve: 1.8, easing: this.easing, ...duration },
          { synthetic: true },
      )
    },
    getMapDataReszvetel() {
      if (this.state.megye && this.state.telepules) {
        const telepulesek = this.map.getSource('telepulesek')._data;
        return (telepulesek.features.filter(t => t.properties.slug === this.state.telepules.slug)[0] || null)
      }
      if (this.state.megye) {
        const megyek = this.map.getSource('megyek')._data
        return (megyek.features.filter(m => m.properties.slug === this.state.megye.slug)[0] || null)
      }
      return null
    },
  },
}

</script>
<style src='maplibre-gl/dist/maplibre-gl.css'></style>
<style lang="scss">
</style>
