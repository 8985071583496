import { config } from '@/config'

let version = Date.parse("2024-05-01 00:00:00").toString(24)

export default {
  setVersion(timestamp) {
    version = Date.parse(timestamp).toString(24)
  },
  getBySlug(slug, params, addVersion = true) {
    let url = config.api.backendUrl + config.api.electionApiPath + '?slug=' + slug + (addVersion ? '&v=' + version : '')
    if (params) {
      url += '&params=' + JSON.stringify(params)
    }
    return fetch(url)
  },

  getEpList() {
    return this.getBySlug('ep-listak')
        .then(result => result.ok && result.json() || Promise.reject(result))
  },

  getTelepulesek(nev) {
    return this.getBySlug('telepules', { nev })
  },
  getEvkJeloltek(mSlug, tSlug, evk, hasEvk) {
    return this.getBySlug('onk-evkjeloltek', { mSlug, tSlug, evk, hasEvk })
  },
  getMegyekReszvetelTerkep() {
    return this.getBySlug('onk-terkep-megyek-reszvetel')
  },
  getKeruletekReszvetelTerkep(mSlug, megyeiJoguOnly) {
    return this.getBySlug('onk-terkep-keruletek-reszvetel',{ mSlug, megyeiJoguOnly })
  },
  getMegyekEredmenyTerkep() {
    return this.getBySlug('onk-terkep-megyek-eredmeny')
  },
  getMegyekEredmeny() {
    return this.getBySlug('onk-megyek-eredmeny')
  },
  getKeruletekEredmenyTerkep(mSlug) {
    return this.getBySlug('onk-terkep-keruletek-eredmeny',{ mSlug })
  },
  getMegyeiJoguKozgyulesEredmenyTerkep() {
    return this.getBySlug('onk-terkep-megyeijogu-kozgyules-eredmeny')
  },
  getVerzio() {
    return this.getBySlug('onk-verzio', null, false)
  },
  getEpEuResult(onlyLatest = true) {
    return this.getBySlug('ep-eu-result', { onlyLatest })
  },
  getEpNationalResults() {
    return this.getBySlug('ep-national-results', { onlyLatest: true })
  },
  getEpNationalResult(countryId, onlyLatest) {
    const upperCaseCountryId = countryId.toUpperCase();
    return this.getBySlug('ep-national-result', {countryId: upperCaseCountryId, onlyLatest}, false)
  },
  getEpTurnout(countryId, onlyLatest) {
    const upperCaseCountryId = countryId.toUpperCase();
    return this.getBySlug('ep-turnout', {countryId: upperCaseCountryId, onlyLatest})
  },
  getEpGroup() {
    return this.getBySlug('ep-group')
  },
  getEpNationalDescription() {
    const url = `${config.api.backendUrl}/api/unique-groups/slug/map-descriptions`;
    return fetch(url);
  },
  getOrszagosReszvetel() {
    return this.getBySlug('onk-reszvetel')
  },
  getSzavazokor(maz, taz, sorszam) {
    return this.getBySlug('onk-szavazokor', { maz, taz, sorszam })
  },
  getEpHuResult() {
    return this.getBySlug('ep-hu-nvi-result', {})
  },
  getEpHuResultMegyek() {
    return this.getBySlug('ep-hu-nvi-results-by-megyek', {})
  },
  getEpHuResultTelepulesek(maz) {
    return this.getBySlug('ep-hu-nvi-results-by-telepulesek', { maz })
  },
}
