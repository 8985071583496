<template>
  <picture>
    <template v-if="breakpoint">
      <source v-for="(width, label) in breakpoints" :key="label" :srcset="srcset(label, types[label] || null)" :media="`(max-width: ${width}px)`">
    </template>
    <img
        v-if="typedSrc(breakpoint, types[breakpoint] || null)"
        :src="typedSrc(breakpoint, types[breakpoint] || null)"
        :srcset="srcset(breakpoint, types[breakpoint] || null)"
        :loading="loading"
        :alt="alt"
        class="placeholder_"
        @click="$emit('click')"
    >
  </picture>
</template>
<script>
import { getSrcSet, getTypedImage } from '@/utils/typed-image'

export default {
  props: {
    breakpoints: {
      type: Object,
      default: () => ({
        xs: 375,
        s: 500,
        m: 768,
        l: 1200,
      }),
    },
    breakpoint: {
      type: String,
      default: 'xl',
    },
    type: {
      type: String,
      default: null,
    },
    types: {
      type: Object,
      default: () => ({}),
    },
    src: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: '',
    },
    loading: {
      type: String,
      default: 'lazy',
    },
  },
  methods: {
    typedSrc(breakpoint, type) {
      const [realType, realBreakpoint] = (type || '').includes(':') ? type.split(':') : [type, breakpoint]
      return getTypedImage(this.src, realType || this.type, realBreakpoint)
    },
    srcset(breakpoint, type) {
      return this.typedSrc(breakpoint) ? getSrcSet(this.typedSrc(breakpoint, type)) : ''
    },
  },
}
</script>
