<template>
<div v-if="show">
  <div v-if="electionType === 'ov'" class="ov2024-more ov2024-more--ov">
    <div>
      <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          fill-rule="evenodd"
          stroke-linejoin="round"
          stroke-miterlimit="2"
      >
        <path d="M200 100.005H99.995v.001l-99.994.002V200.01h100.003v-.001H200V100.005z" fill="#2fa553" fill-rule="nonzero" />
        <path d="M-.003 0h100.005v100.004H-.003z" fill="#ff3e3e" />
        <path d="M149.997 100.004c27.615 0 50.002-22.387 50.002-50.002S177.612 0 149.997 0 99.995 22.387 99.995 50.002s22.387 50.002 50.002 50.002" fill="#3c3c3b" fill-rule="nonzero" />
        <path d="M34.608 70.866v-8.04l15.354-12.701 2.624-2.38c.728-.744 1.29-1.511 1.686-2.301s.593-1.67.593-2.634c0-1.056-.224-1.962-.672-2.713a4.55 4.55 0 0 0-1.861-1.738c-.79-.408-1.702-.613-2.732-.613s-1.94.207-2.724.623a4.29 4.29 0 0 0-1.817 1.83c-.431.804-.646 1.782-.646 2.934H33.805c0-2.894.649-5.386 1.95-7.477s3.134-3.696 5.507-4.823 5.153-1.689 8.34-1.689c3.295 0 6.153.526 8.572 1.576s4.287 2.531 5.607 4.431 1.978 4.135 1.978 6.693c0 1.58-.328 3.154-.984 4.714s-1.837 3.285-3.535 5.175-4.122 4.139-7.257 6.752L50.125 61.7v.244H66.2v8.921H34.608zm104.614-41.443l10.615 17.122h.319l10.612-17.122h5.871l-12.943 20.579 12.943 20.579h-5.871l-10.612-16.8h-.319l-10.615 16.8h-5.868l13.265-20.579-13.265-20.579h5.868zM34.607 170.867v-8.04l15.354-12.701 2.624-2.38c.728-.745 1.29-1.511 1.686-2.301s.593-1.67.593-2.633c0-1.057-.224-1.963-.672-2.714s-1.07-1.329-1.861-1.738-1.702-.613-2.733-.613-1.939.207-2.723.623a4.29 4.29 0 0 0-1.817 1.83c-.431.804-.646 1.782-.646 2.934h-10.61c0-2.894.648-5.386 1.949-7.477s3.134-3.696 5.507-4.823 5.153-1.689 8.339-1.689c3.296 0 6.154.526 8.573 1.576s4.287 2.531 5.607 4.431 1.978 4.135 1.978 6.693c0 1.58-.329 3.154-.984 4.714s-1.838 3.285-3.536 5.175-4.122 4.139-7.256 6.752l-3.859 3.213v.244h16.076v8.921H34.607zm133.116-15.395v8.685h-4.741v6.429h-10.694v-6.429H132.27v-8.685l16.562-26.044h14.15v26.044h4.741zm-24.44-.319v.319h9.246v-14.469h-.319l-8.926 14.149z" fill="#fff" />
      </svg>
      <span>
      Választás 2024
    </span>
    </div>
    <a href="https://telex.hu/melleklet/valasztas-2024" target="_blank">
      <div v-if="windowWidth < 351" class="ov2024-more__btn">
        Tovább
      </div>
      <div v-if="windowWidth > 350" class="ov2024-more__btn">
        Tovább a mellékletre
      </div>
    </a>
  </div>
  <div v-if="electionType === 'ep'" class="ov2024-more ov2024-more--ep">
    <div>
      <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 61 15"
          fill-rule="evenodd"
          stroke-linejoin="round"
          stroke-miterlimit="2"
      >
        <path d="M61 0H0v15.047h61z" fill="#174096" />
        <path d="M34.49 2.344l1.283 3.95h4.153l-3.36 2.442 1.283 3.95-3.36-2.442-3.36 2.442 1.283-3.95-3.36-2.442h4.153l1.283-3.95z" fill="#f9f101" />
        <path d="M2.32 12.706V2.344h7.468V4.61H5.132v1.78h4.271v2.267H5.132v1.782h4.635v2.266H2.32zm18.066.07v-2.024l3.866-3.198.66-.6c.183-.187.325-.38.426-.579a1.48 1.48 0 0 0 .149-.663c0-.266-.058-.495-.171-.683a1.14 1.14 0 0 0-.468-.437c-.198-.103-.428-.156-.688-.156a1.43 1.43 0 0 0-.685.158c-.197.104-.349.257-.458.46a1.57 1.57 0 0 0-.159.739h-2.673c0-.728.163-1.357.491-1.882s.788-.932 1.386-1.215 1.297-.424 2.099-.424c.83 0 1.549.133 2.158.397s1.08.638 1.412 1.116.497 1.041.497 1.685c0 .398-.08.793-.247 1.186s-.461.827-.89 1.303-1.038 1.041-1.827 1.7l-.972.809v.062h4.048v2.246h-7.953zm20.492-.01v-2.024l3.866-3.198.66-.6c.183-.187.325-.38.424-.579a1.46 1.46 0 0 0 .149-.663c0-.266-.056-.495-.169-.683a1.14 1.14 0 0 0-.468-.437c-.198-.103-.428-.156-.688-.156a1.43 1.43 0 0 0-.685.158c-.197.104-.35.257-.457.46s-.162.449-.162.738h-2.672c0-.727.163-1.356.491-1.881s.788-.932 1.386-1.215 1.297-.424 2.099-.424c.83 0 1.549.132 2.158.397s1.08.638 1.412 1.116.497 1.041.497 1.685a3.03 3.03 0 0 1-.247 1.186c-.166.393-.462.827-.89 1.303s-1.038 1.041-1.827 1.7l-.972.809v.062h4.048v2.246h-7.953zM18.716 4.079c.32.55.48 1.193.48 1.927 0 .742-.164 1.386-.492 1.928a3.29 3.29 0 0 1-1.382 1.254c-.591.294-1.282.441-2.072.441h-1.558v3.076h-2.814V2.344h4.473c.768 0 1.441.151 2.018.454a3.31 3.31 0 0 1 1.347 1.281zm-2.644 2.692c.127-.216.19-.471.19-.765a1.45 1.45 0 0 0-.19-.761 1.25 1.25 0 0 0-.541-.486c-.235-.113-.517-.169-.847-.169h-.992v2.854h.992c.33 0 .613-.059.847-.175a1.27 1.27 0 0 0 .541-.498zm42.606 2.195v2.185h-1.193v1.62h-2.692v-1.62h-5.041V8.966l4.169-6.557h3.563v6.557h1.193zm-6.152-.082v.082h2.328V5.322h-.082l-2.246 3.562z" fill="#fff" />
      </svg>
      <span>
        Európa választ
      </span>
    </div>
    <a href="https://telex.hu/melleklet/ep-2024" target="_blank">
      <div v-if="windowWidth < 411" class="ov2024-more__btn">
        Tovább
      </div>
      <div v-if="windowWidth > 410" class="ov2024-more__btn">
        Tovább a mellékletre
      </div>
    </a>
  </div>
</div>
</template>
<script>
import { config } from "@/config"

export default{
  props: {
    electionType: {
      type: String,
      default: 'ov', // "ov" or "ep"
    },
  },
  data() {
    return{
      windowWidth: null,
      hasHideQueryParam: false,
    }
  },
  computed: {
    show() {
      return config.embed.showMores && !this.hasHideQueryParam
    },
  },
  mounted() {
    this.windowWidth =window.innerWidth
    this.hasHideQueryParam = this.$route.query?.hideMore
    this.$nextTick(() => {
      window.addEventListener('resize',this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize',this.onResize)
  },
  methods: {
    onResize() {
      this.windowWidth =window.innerWidth
    },
  },
}
</script>
