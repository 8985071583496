<template>
<div v-if="show" class="powered-by">
  <img class="logo" src="@/assets/images/telex-logo.png" /> <a href="https://telex.hu" target="_blank">Telex.hu</a> beágyazás
</div>
</template>
<script>
import { config } from "@/config";

export default {
  data() {
    return {
      show: config.embed.showPoweredBy,
    }
  },
}
</script>
<style lang="scss">
.powered-by {
  margin-top: 7px;
  margin-bottom: 5px;
  margin-right: 5px;
  float: right;
  font-family: Arial;
  a {
    font-weight: bold;
    color: #096;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .logo {
    height: 17px;
    vertical-align: middle;
  }
}
</style>
