<template>
  <div class="list__faq">
    <div class="list__faq__disclaimer hint_box hint_box--title">
      <svg
          viewBox="0 0 10 10"
          fill-rule="evenodd"
          stroke-linejoin="round"
          stroke-miterlimit="2"
          width="16px"
      >
        <path d="M10 5.002v-.036a4.99 4.99 0 0 0-.303-1.684l-.009-.024-.034-.088-.044-.108A5.01 5.01 0 0 0 5.039 0h-.037-.039c-.634.005-1.239.127-1.796.346l-.111.045-.11.048C1.221 1.219.016 2.95 0 4.963v.035.038c.005.633.126 1.239.346 1.795l.045.111C1.145 8.727 2.908 9.984 4.966 10h.032.037c.548-.004 1.075-.095 1.567-.262l.113-.04a5.01 5.01 0 0 0 3.285-4.665L10 5.002zM5.139 1.574c.487-.004.9.415.898.911-.002.501-.405.908-.899.908s-.9-.406-.902-.905a.91.91 0 0 1 .903-.914zm1.214 6.244c-.312.346-.693.56-1.165.592-.257.018-.515.034-.769-.035-.465-.127-.776-.552-.716-1.03l.228-1.41.209-1.225a.71.71 0 0 0-.005-.204.23.23 0 0 0-.201-.211c-.067-.013-.139-.005-.204-.023-.092-.025-.192-.049-.268-.102-.125-.088-.1-.266.041-.326a.62.62 0 0 1 .228-.044l.882-.002V3.8h.902c.197.001.289.085.331.276.036.167-.011.323-.041.483l-.372 2.022-.107.626c-.006.035-.004.072-.004.108.005.41.264.465.561.346.084-.034.158-.091.239-.134.139-.075.213-.077.268-.012.067.078.061.194-.037.303z" fill-rule="nonzero" />
      </svg>
      <p>
        Forrás: Nemzeti Választási Iroda. További kérdésekre a
        <a href="https://www.valasztas.hu/onk2024-gyik" target="_blank">Nemzeti Választási Iroda oldalán találni válaszokat</a>
        .
      </p>
    </div>
    <div class="list__faq__container">
      <div class="list__faq__content">
        <accordion
            v-for="(current, index) in faq.filter(f => f.t === 'u')"
            :key="index"
            class="accordion"
        >
          <template #title>
            <div class="accordion__title">
              <div v-html="current.q" />
            </div>
          </template>
          <template #content>
            <div class="accordion__content">
              <div v-html="current.a" />
            </div>
          </template>
        </accordion>
      </div>
      <div class="list__faq__content">
        <accordion
          v-for="(current, index) in faq.filter(f => f.t === 'd')"
          :key="index"
          class="accordion">
          <template #title>
            <div class="accordion__title">
              <div v-html="current.q" />
            </div>
          </template>
          <template #content>
            <div class="accordion__content">
              <div v-html="current.a" />
            </div>
          </template>
        </accordion>
      </div>
    </div>
  </div>
</template>
<script>
import Accordion from '@/components/sub-components/Accordion'
import sizeReporter from '@/mixins/sizeReporter'

export default {
  name: 'OvVotingFaq',
  mixins: [sizeReporter],
  components: {
    Accordion,
  },
  data() {
    return {
      faq: [
        {
          t: 'u',
          q: 'Mi az a töredékszavazat és a kompenzációs lista?',
          a: 'Kompenzációs lista nincs minden településen, ilyet kizárólag a 10 ezernél több lakosú településeken és a fővárosi kerületekben lehet állítani. Ez az úgynevezett „vegyes választási rendszer”, ami azt jelenti, hogy az adott település körzeteiben megválasztott képviselők mellett erről a listáról is bekerülhetnek a képviselők. A kompenzációs listák működését, és a töredékszavazat jelentését <a href="/belfold/2024/06/05/valasztasi-kisokos-kompenzacios-lista-toredekszavazatok-onkormanyzati-egyeni-jelolt-listas-jelolt">választási kisokosunkban</a> magyaráztuk el. ',
        },
        {
          t: 'u',
          q: 'Mi lesz a szavazatommal, ha a választott pártom nem jut ki az EP-be?',
          a: 'A válasz egyszerűen: bármennyire is szomorúan hangzik, az azokra a pártokra leadott szavazatok mennek kukába, amelyek nem érik el az ötszázalékos bejutási küszöböt. Azonban a billegő pártokra leadott szavazat is lehet értékes, ahogy <a href="/belfold/2024/05/28/taktikai-szavazas-ep-onkormanyzati-valasztas-matematika-esely-lista-csoka-endre">a taktikai szavazóknak szóló útmutatónkban</a> is írtuk. Arról, hogy miért érheti meg kisebb pártokra is szavazni, illetve hogyan váltják át a szavazatokat mandátumra <a href="/belfold/2024/06/07/valasztasi-kisokos-ep-valasztas-kompenzacios-lista-elveszett-szavazatok">választási kisokosunkban</a> bővebben írtunk. ',
        },
        {
          t: 'u',
          q: 'Mi az a vármegyei lista? Kik szavazhatnak?',
          a: 'A vármegyei listákra azért van szükség, mert a 19 vármegyének saját közgyűlése is van, ide kell képviselőket választani. A fővárosi és a 25 megyei jogú városban élő szavazók nem szavazhatnak vármegyei listákra, de az összes többi településen élő szavazó igen. A részletekkel <a href="/belfold/2024/06/03/varmegyei-listak-onkormanyzati-valasztas-2024-valasztasi-kisokos-kire-szavazunk">választási kisokosunkban</a> külön is foglalkoztunk.',
        },
        {
          t: 'u',
          q: 'Hogy működik a most bevezetett fővárosi listás választás?',
          a: 'A Fővárosi Közgyűlésbe a jelenlegitől eltérően nem a kerületekben megválasztott polgármesterek, hanem a pártok és jelölőszervezetek által összeállított listákról megválasztott képviselők ülnek majd be. A fővárosban összesen 10 pártnak vagy szervezetnek sikerült listát állítania. Arról, hogy kik állították ilyen listát, és miért fontos ez a választás a főpolgármesternek <a href="/belfold/2024/06/04/fovaros-budapest-listas-valasztas-onkormanyzati-valasztas-2024-valasztasi-kisokos">választási kisokosunkban</a> részletesebben is írtunk.',
        },
        {
          t: 'u',
          q: 'Kiket küldhetünk Brüsszelbe? Hogy lesz EP-listából EP-képviselő?',
          a: 'Magyarország 21 képviselőt küldhet az Európai Parlamentbe, akiket a pártok és jelölő szervezetek listáiról választhatunk ki. Hogyan számolják ki, hogy kinek mennyi képviselői hely jut? Miért csak 21 képviselőt küldhetünk?  A részletekkel <a href="/belfold/2024/05/31/europai-parlament-valasztas-2024-jeloltek-kisokos">választási kisokosunkban</a> külön is foglalkoztunk.',
        },
        {
          t: 'u',
          q: 'Hogy szavazhatnak a külképviseleten élők, és azok, akik nincsenek otthon a választás napján?',
          a: 'Eltérő a helyzet, ha valaki nem Magyarországon él, ha nincs bejelentett lakcíme, vagy esetleg tartózkodási helyén vagy épp külföldön, egy külképviseleten szeretne szavazni. <a href="/belfold/2024/05/30/kulkepviseleti-szavazas-atjelentkezes-mashol-szavazas-onkormanyzati-ep-valasztas-kisokos">Választási kisokosunkban</a> ezeket az opciókat részletesen is végigvettük.',
        },
        {
          t: 'u',
          q: 'Hány szavazólapot kapok a szavazóhelyiségben?',
          a: 'Az attól függ, hogy milyen településen él. Amennyiben nemzetiségi szavazó és a fővárosban voksol, akkor akár nyolc szavazólapot is kaphat, míg ha megyei jogú városban él, előfordulhat, hogy csak hármat. Az sem mindegy, hogy tízezer lakos alatti településen, vagy tízezer felettiben él. A részletekkel külön foglalkoztunk <a href="/belfold/2024/05/28/valasztas-2024-onkormanyzat-ep-valasztas-szavazolapok-kisokos">választási kisokosunkban</a>.',
        },
        {
          t: 'u',
          q: 'Milyen feltételekkel tudok szavazni?',
          a: 'A szavazóhelyiségben az a választópolgár szavazhat, aki a kinyomtatott szavazóköri névjegyzékben szerepel. A választópolgárnak igazolnia kell személyazonosságát (érvényes személyazonosító igazolvánnyal, útlevéllel vagy vezetői engedéllyel), valamint a lakcímét vagy személyi azonosítóját (lakcímkártyával). A választópolgár a fentiek után megkapja a lebélyegzett szavazólapokat, az átvételt a névjegyzék aláírásával igazolja.',
        },
        {
          t: 'u',
          q: 'Melyik szavazókörben szavazhatok?',
          a: 'A választási értesítő tartalmazza a szavazókör pontos címét, továbbá a szavazókörök jegyzéke megtalálható a <a href="https://vtr.valasztas.hu/ep2024/valasztopolgaroknak/szavazasi-informaciok/szavazohelyiseg-kereses">Nemzeti Választási Iroda honlapján</a>.',
        },
        {
          t: 'u',
          q: 'Mikor vannak nyitva a szavazóhelyiségek?',
          a: 'Szavazni a szavazás napján (2024. június 9-én, vasárnap) reggel 6.00 órától 19.00 óráig lehet.',
        },
        {
          t: 'u',
          q: 'Érvényes-e a szavazólap, ha nincs lepecsételve?',
          a: 'Nem érvényes az a szavazólap, amely nincs ellátva a hivatalos bélyegzőlenyomattal.',
        },
        {
          t: 'u',
          q: 'Van-e lehetőség rontás esetén a szavazólap kicserélésére?',
          a: 'Ha a választópolgár a szavazólap urnába dobása előtt jelzi, hogy a szavazólap kitöltését elrontotta, a rontott szavazólapot a szavazatszámláló bizottság bevonja, egy külön e célra szolgáló borítékba helyezi, és a rontott szavazólap helyett új szavazólapot ad ki. Egy választópolgár rontás miatt szavazólap fajtánként csak egyszer kérhet új szavazólapot. Ha a választópolgár a szavazatát bedobta az urnába, már nem kérhet cserét.',
        },
        {
          t: 'u',
          q: 'Mikor kapok értesítőt, hogy hol tudok szavazni?',
          a: 'A Nemzeti Választási Iroda a 2024. április 3-i névjegyzéki adatok alapján legkésőbb 2024. április 19-ig (péntek) küldi meg az értesítést arról, hogy a magyarországi lakcímmel rendelkező választópolgár szerepel a névjegyzékben, hol és mikor szavazhat, illetve, hogy szerepel-e és ha igen, mely nemzetiségi névjegyzékben.',
        },
        {
          t: 'u',
          q: 'Mit kell tenni, ha valaki nem kapta meg az értesítőt?',
          a: 'A választópolgár, ha nem kapja meg az értesítőt, ha az megsemmisül vagy elvész, akkor bármely helyi választási irodától - amelyet a polgármesteri hivatalban talál - új értesítőt igényelhet. Az értesítő bemutatása nem feltétele a szavazásnak.',
        },
        {
          t: 'u',
          q: 'Lejárt érvényességű okmányokkal lehet-e szavazni?',
          a: 'A lejárt érvényességű okmányokkal rendelkezők nem szavazhatnak, őket a szavazatszámláló bizottság köteles visszautasítani. A szavazásra abban az esetben sincs mód, ha a választópolgárt a bizottság tagja vagy tagjai személyesen ismerik, és a névjegyzéken is szerepel. A szavazás ideje alatt a kijelölt kormányablakok ügyletet tartanak okmánypótlás céljából. Fontos, hogy a választópolgárok a szavazás napja előtt vizsgálják meg okmányaik érvényességét, és időben gondoskodjanak az érvényes dokumentumok beszerzéséről.',
        },
        {
          t: 'u',
          q: 'Érvényes-e a szavazat, ha nincs borítékban?',
          a: 'Igen. Ugyanakkor a nemzetiségi önkormányzati választáson szavazó nemzetiségi választópolgárnak csak a zöld színű nemzetiségi szavazólapokat kell a zöld színű borítékba zárnia!',
        },
        {
          t: 'u',
          q: 'Lehet-e levélben, meghatalmazással vagy online szavazni?',
          a: 'Igen. Ugyanakkor a nemzetiségi önkormányzati választáson szavazó nemzetiségi választópolgárnak csak a zöld színű nemzetiségi szavazólapokat kell a zöld színű borítékba zárnia!',
        },
        {
          t: 'u',
          q: 'Ha a szavazás napján nem a lakóhelyemen tartózkodom, hogyan tudok szavazni? ',
          a: 'Az önkormányzati választáson átjelentkezéssel csak az a választópolgár tud szavazni, aki a választás kitűzését megelőző 30. napon, illetve az átjelentkezésre irányuló kérelme elbírálásakor ugyanazon tartózkodási hellyel rendelkezik, és tartózkodási helyének érvényessége legalább a szavazás napjáig tart. Ha úgy dönt, hogy a tartózkodási helyén szeretne szavazni, akkor kérheti átjelentkezését 2024. április 4-től (csütörtök) 2024. május 31-én (péntek) 16.00 óráig a választási irodától.  Az átjelentkezési kérelmet 2024. április 4-től (csütörtök) lehet benyújtani.',
        },
        {
          t: 'u',
          q: 'Lehet-e külképviseleten szavazni?',
          a: 'Az önkormányzati választáson nem lehet. Az Európai Parlament tagjainak választás azonban szavazhat külképviseleten is.  ',
        },
        {
          t: 'd',
          q: 'Külföldön élő (magyarországi lakcím nélküli) magyar állampolgár szavazhat-e az önkormányzati választáson?',
          a: 'Nem, mivel nem rendelkezik magyarországi lakcímmel.',
        },
        {
          t: 'd',
          q: 'Ki igényelhet mozgóurnát, és hogyan?',
          a: 'Azok a szavazóköri névjegyzékben szereplő választópolgárok, akik mozgásukban egészségi állapotuk, illetve fogyatékosságuk, vagy fogva tartásuk miatt akadályozottak. Aki  a mozgóurnát más indokkal (pl. munkavégzés, kényelmi szempontok) kéri, annak a kérését a választási iroda, illetve a szavazatszámláló bizottság elutasítja. A mozgóurna iránti kérelmet április 4-étől kezdve lehet benyújtani. A mozgóurnát csak olyan címre lehet kérni, ahol a választópolgár a szavazóköri névjegyzéken szerepel. Fontos, hogy aki mozgóurnát kért, a szavazókörben „hagyományos módon” nem, kizárólag mozgóurnával szavazhat. Amennyiben a mozgóurna a szavazóhelyiségben van, akkor viszont leadhatja szavazatát a szavazóhelyiségben is.',
        },
        {
          t: 'd',
          q: 'Hogyan szavazhatok lakcímemtől eltérően mozgóurnával?',
          a: 'A helyi önkormányzati képviselők választása esetén a választópolgár csak oda kérheti átjelentkezését, ahol a választás kitűzését megelőző 30. napon tartózkodási hellyel rendelkezett, illetve az átjelentkezésre irányuló kérelme elbírálásakor ugyanazon tartózkodási hellyel rendelkezik, és tartózkodási helyének érvényessége legalább a szavazás napjáig tart. A tartózkodási helyére ez esetben 2024. május 31. 16.00 óráig igényelhet mozgóurnát, amely egyben átjelentkezési kérelemnek is minősül. Továbbá kizárólag olyan címre igényelhető mozgóurna, amely cím annak a szavazókörnek a területén  található, ahol a választópolgár a szavazóköri névjegyzékben szerepel.',
        },
        {
          t: 'd',
          q: 'Ha a szavazás napján kórházban, vagy büntetésvégrehajtási intézetben vagyok, hogyan tudok szavazni? ',
          a: 'A fenti jogszabályi hivatkozás miatt nem tudnak szavazni a választópolgárok kórházban vagy büntetésvégrehajtási intézetben az önkormányzati és nemzetiségi választásoknál. Csak abban az esetben, ha az intézmény címe ugyan abban a szavazókörben van, mint a polgárnak a lakcíme. Ilyen esetben a választópolgár  csak az Európai Parlament tagjaninak a választásán tud szavazni, akár mozgóurnával.',
        },
        {
          t: 'd',
          q: 'Lesznek-e akadálymentesített szavazókörök?',
          a: 'Igen. Az a választópolgár (nemzetiségi is), aki 2024. június 5. 16.00 óráig kéri, hogy akadálymentes szavazóhelyiségben szavazhasson, és akinek a lakcíme szerinti kijelölt szavazóhelyiség nem akadálymentes, a helyi választási iroda átteszi egy akadálymentes szavazókörbe. Az erre irányuló  formanyomtatvány benyújtható személyesen vagy kézbesítési meghatalmazott útján bármely helyi választási irodához. Továbbá online elektronikus azonosítással.',
        },
        {
          t: 'd',
          q: 'Mely szervezetek lehetnek jelölő szervezetek?',
          a: 'Jelölő szervezet lehet a helyi önkormányzati képviselők és polgármesterek választásán a választás kitűzésekor a civil szervezetek bírósági nyilvántartásában jogerősen szereplő párt, valamint egyesület (a szakszervezet kivételével). A jelölő szervezet bejelentése megtehető bármelyik területi (vagyis vármegyei ill. fővárosi) választási bizottságnál, illetve a Nemzeti Választási Bizottságnál.',
        },
        {
          t: 'd',
          q: 'Hol nem lehet gyűjteni az ajánlást? ',
          a: 'Nem gyűjthető ajánlás munkahelyen, államigazgatási szerveknél, a Magyar Honvédségnél, tömegközlekedési eszközön, iskolákban, óvodákban, kórházakban, rendelőkben, illetve a tulajdonos előzetes, írásbeli hozzájárulásának hiányában közforgalom számára nyitva álló magánterületen.',
        },
        {
          t: 'd',
          q: 'Saját kezűleg kell kitölteni az ajánlóívet?',
          a: 'Nem. Más személy is beírhatja az ajánló választópolgár adatait, ugyanakkor az ajánlóív csak akkor érvényes, ha az ajánló választópolgár azt saját kezűleg írja alá.',
        },
        {
          t: 'd',
          q: 'Külföldön élő (magyarországi lakcím nélküli) magyar állampolgár indulhat-e jelöltként az önkormányzati választáson?',
          a: 'Igen, amennyiben regisztrált, és szerepel a központi névjegyzékben az ajánlóív kikérésekor, illetve a lista leadásakor.',
        },
        {
          t: 'd',
          q: 'Uniós állampolgár indulhat-e jelöltként az önkormányzati választáson?',
          a: 'Igen, mind a polgármesteri, mind a képviselő választáson, a vármegyei listára is felveheti a jelölőszerv.',
        },
        {
          t: 'd',
          q: 'Mikor kezdődik a kampány?',
          a: 'A kampányidőszak 2024. április 20-án (szombat 0 órától) kezdődik és a szavazás napján, 2024. június 9-én (vasárnap) 19.00 óráig tart.',
        },
        {
          t: 'd',
          q: 'Van kampánycsend?',
          a: 'Nincs kampánycsend, azonban nem folytatható kampánytevékenység a szavazás napján a szavazóhelyiségben, a szavazóhelyiséget magában foglaló épületben, valamint a szavazóhelyiséget magában foglaló épületnek a szavazóhelyiség megközelítését szolgáló bejáratától számított 150 méteres távolságon belüli közterületen. A szavazás napján választási gyűlés sem tartható, illetve politikai reklámot sem lehet közzétenni.',
        },
        {
          t: 'd',
          q: 'Mettől meddig gyűjthetik a jelöltek az ajánlásokat?',
          a: 'Ajánlást gyűjteni polgármesterre, főpolgármesterre, vármegyei képviselőre, nemzetiségi képviselőre 2024. április 20-tól (szombat) 2024. május 6-án 16.00 óráig (hétfő) lehet. Vármegyei, nemzetiségi területi és országos listára 2024. május 7-én 16.00 óráig (kedd) gyűjthető ajánlás.',
        },
        {
          t: 'd',
          q: 'Lehet-e több jelöltet ajánlani?',
          a: 'Igen, egy választópolgár akár több jelöltet, ill. listát is ajánlhat, de egy jelöltet, ill. listát csak egyszer.',
        },
        {
          t: 'd',
          q: 'Szükséges-e az adott választókerületben lakóhellyel rendelkezni ahhoz, hogy a választópolgár jelöltet ajánlhasson?',
          a: 'Igen, szükséges. Jelöltet csak az a választópolgár ajánlhat, akinek lakcíme a választókerületben van. Amennyiben a választópolgár lakóhelye mellett bejelentett tartózkodási hellyel is rendelkezik, és úgy dönt, hogy a tartózkodási helyén szeretne szavazni, akkor az átjelentkezés után a választópolgár a bejelentett tartózkodási helye szerinti választókerületben ajánlhat jelöltet.',
        },
        {
          t: 'd',
          q: 'Szükséges-e az adott településen, megyében lakóhellyel rendelkezni a jelöltté váláshoz?',
          a: 'Nem. A választópolgár bármely választókerületben választható, nem kell azon a településen, vármegyében laknia, ahol önkormányzati képviselővé, polgármesterré, főpolgármesterré jelölik.',
        },
        {
          t: 'd',
          q: 'Kiadják a jelölő szervezeteknek a személyes adataimat?',
          a: 'A jelöltek és a listát állítók közvetlen politikai kampány céljából megkaphatják a névjegyzékben szereplő választópolgárok nevét és lakcímét, valamint életkorát és nemét. Lehetőség van  azonban arra, hogy a választópolgár megtiltsa az adatai kampánycélú kiadását. Az adatkiadás megtiltása iránti kérelmet a választópolgár bármely helyi választási irodának benyújthatja személyesen vagy kézbesítési meghatalmazott útján. Aki korábban már kérte az adatletiltást, annak a kérelme visszavonásig érvényes, most nem kell új kérelmet beadnia.',
        },
      ],
    }
  },
}
</script>
<style lang="scss">
.list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include xl {
    margin: 0 auto;
    width: 1120px;
  }
  &__faq {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin: 0 0 40px 0;
    @include m {
      margin: 0 0 60px 0;
    }
    @include l {
      margin: 0;
      // grid-template-columns: 1fr 1fr;
      // grid-gap: 60px;
      // align-items: start;
    }
    &__container {
      display: grid;
      place-content: center;
      width: 100%;
      grid-template-columns: 1fr;
      @include l {
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 60px;
      }
      @include xl {
        grid-gap: 0 90px;
      }
      &__solo {
        grid-template-columns: 1fr;
      }
      &__title {
        font-size: 1.2rem;
        font-weight: var(--bold);
        margin: 0 auto 30px auto;
        color: var(--black);
      }
    }
    .accordion {
      width: 100%;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
