<template>
  <div id="app" class="light-mode">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
}
</script>
<style lang="scss">
// iframe-es, standalone buildbe kerül csak bele
body {
  margin: 0;
  .maplibre-container {
    height: 600px!important;

    .ov2024-list__result {
      @include l {
        height: 100%;
      }
    }
    .map-sizer {
      height: 333px!important;
      @include l {
        height: 100%!important;
      }
    }

    .ov2024-list__result {
      height: 250px;
      @include l {
       height: 500px;
      }
    }
  }
}
</style>
