<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 50 60"
      fill-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="2"
      class="accordion__content--eplist__img"
  >
    <path d="M-.001.002h50v60h-50z" fill="#fff" />
    <path d="M12.815 60.002l.1-23.824 6.93-3.163c1.736 3.764 3.201 6.47 5.038 7.883 0 0 .69-1.001 1.244-1.385 2.42-1.68 8.916-6.314 8.916-7.666.871.394 2.34 1.513 4.257 4.376l-5.28 23.779H12.815z" fill="#fff" />
    <path d="M24.637 41.357s-5.782-6.934-5.136-8.286c0 0-1.697-1.287-2.163-2.117-.744-1.323-1.834-3.602-1.915-5.815-.121-3.303-.194-8.788 1.346-13.488.87-4.014 2.697-7.044 6.725-7.811 9.302-1.362 16.056.301 14.844 13.224 0 0 3.254 1.55.143 7.07-.354 2.033-1.544 4.137-3.067 4.887l-.373 3.154c-2.104 3.167-5.68 7.108-10.405 9.182h0z" fill="#ddd" />
    <path d="M17.549 60.002H-.001V45.608l.086-.186c.366-.778 1.931-3.794 3.988-4.615 4.347-1.734 8.308-4.084 8.308-4.084-.121-.297.856-1.04 1.194-1.308 1.108-.881 3.087-1.631 4.154-2.037.49-.186 1.456-.301 1.567-.182 0 0-4.225 2.42-4.479 3.466l.405 4.784 2.326 18.558zm14.316 0l2.054-7.05c.826-3.108 4.195-14.052 4.86-16.634-.708-1.516-1.291-2.3-1.864-2.87-.589-.586-1.164-.945-1.687-1.525a2.12 2.12 0 0 0 .93.279c1.404.807 4.542 2.312 4.558 3.764l9.284 4.166v19.871H31.864zM28.03 43.007c-.091.228-.586 1.4-1.358 1.964l.281 15.031h-4.773l1.775-15.117c-.709-.716-1.238-1.82-1.238-1.82s1.926-1.85 2.285-1.635c.339.202 2.809 1.798 3.027 1.577zm-11.55-29.539c-.686 2.991-.913 5.827-1.084 8.644 0 0-4.023-5.499-2.038-10.156-2.381-1.414-3.636-2.907-2.821-3.512 12.736-9.456 25.184-7.351 25.723-3.329 5.42.521 3.176 13.209 3.176 13.209s-.759-.983-1.696-.81-1.859.628-1.859.628c.505-2.254-.528-8.266-.528-8.266-5.081 6.537-13.25 5.8-18.874 3.593zm19.669 12.71c-.05.393 2.576-2.624 2.576-2.624.034 2.97-3.294 5.578-3.294 5.578l.718-2.953z" fill="#999" />
  </svg>
</template>
<script>
export default {
}
</script>
