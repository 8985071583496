<template>
  <div class="frame__container">
    <ov-more election-type="ep" />
    <div class="ep__container">
      <embed-me path="/ep/listak"/>
      <div v-if="showMandatum">
        <accordion class="accordion ep2024 ep2024--results" :open="true">
          <template #title>
            <div class="accordion__title">
              Bejutott jelöltek
            </div>
          </template>
          <template #content>
            <div class="accordion__content accordion__content--ep2024--results">
              <div class="accordion__content--eplist__result">
                <template v-for="epList in bejutottakEpLists">
                  <div>
                    <div class="accordion__title accordion__title__sum">
                      <span class="accordion__title__color accordion__title__sum__color" :style="{ background: epList.color }"></span>
                      <span class="accordion__title__party">{{ epList.nev }}</span>
                      <span class="accordion__title__info">-</span>
                      <span class="accordion__title__info">{{ epList.eredetiJeloltekSzam }} jelöltből <b>{{ epList.jeloltek.length }} mandátum</b></span>
                    </div>
                    <div v-if="epList.jeloltek.length > 0" class="accordion__content--eplist" :key="'bejutott-ep-list-' + epList.nev">
                      <div v-for="(jelolt, index) in epList.jeloltek" :key="'bejutott-jelolt-' + jelolt.neve + '-' + index" class="accordion__content--eplist__item">
                        <typed-image
                            v-if="jelolt.fenykep"
                            :key="'jelolt-' + jelolt.nev + '-kep-' +index "
                            :src="getImageSrc(jelolt.fenykep)"
                            class="accordion__content--eplist__img"
                            type="cikktorzs"
                            breakpoint=""
                            :alt="jelolt.nev"
                        />
                        <silhouette v-else />
                        <span class="accordion__content--eplist__title">
                      <span>{{ index + 1 }}.</span>
                      <span>{{ jelolt.nev }}</span>
                          <!--span><span class="accordion__title__color" :style="{ backgroundColor: epList.color }" />{{ epList.nev }}</span>></span-->
                        </span>
                      </div>
                    </div>
                  </div>
                  </template>
              </div>
            </div>
          </template>
        </accordion>
      </div>
      <div class="ep__container__subtitle">
        Pártlisták
      </div>
      <div>
        <div v-for="epList in electoralEpLists" :key="'ep-list-' + epList.nev">
          <accordion class="accordion ep2024">
            <template #title>
              <div class="accordion__title">
                <span class="accordion__title__color" :style="{ backgroundColor: epList.color }" />
                <span class="accordion__title__party">{{ epList.nev }}</span>
                <span class="accordion__title__info">-</span>
                <span class="accordion__title__info">{{ epList.jeloltek.length }} jelölt</span>
              </div>
            </template>
            <template #content>
              <div class="accordion__content">
                <div class="accordion__content--eplist">
                  <div v-for="(jelolt, index) in epList.jeloltek" :key="'jelolt-' + jelolt.neve + '-' + index" class="accordion__content--eplist__item">
                    <typed-image
                        v-if="jelolt.fenykep"
                        :key="'jelolt-' + jelolt.nev + '-kep-' +index "
                        :src="getImageSrc(jelolt.fenykep)"
                        class="accordion__content--eplist__img"
                        type="cikktorzs"
                        breakpoint=""
                        :alt="jelolt.nev"
                    />
                    <silhouette v-else />
                    <span class="accordion__content--eplist__title">
                      <span>{{ index + 1 }}.</span>
                      <span>{{ jelolt.nev }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </accordion>
        </div>
        <powered-by />
      </div>
      <!--<div>
        <accordion class="accordion ep2024">
          <template #title>
            <div class="accordion__title">
              További jelöltek
            </div>
          </template>
          <template #content>
            <div class="accordion__content">
              <div class="accordion__content&#45;&#45;eplist__result">

              </div>
            </div>
          </template>
        </accordion>
      </div>-->
    </div>
  </div>
</template>

<script>
import electionApi from "@/api/ElectionApi"
import sizeReporter from '@/mixins/sizeReporter'
import Accordion from '@/components/sub-components/Accordion'
import TypedImage from '@/components/sub-components/TypedImage'
import PoweredBy from '@/components/sub-components/PoweredBy'
import EmbedMe from '@/components/sub-components/EmbedMe'
import OvMore from "@/components/sub-components/OvMore.vue";
import Silhouette from "@/components/sub-components/Silhouette.vue";

export default {
  name: 'EpList',
  mixins: [
    sizeReporter,
  ],
  components: {
    PoweredBy,
    Accordion,
    TypedImage,
    EmbedMe,
    OvMore,
    Silhouette,
  },
  data() {
    return {
      electoralEpLists: null,
      bejutottakEpLists: null,
      showMandatum: true,
    }
  },
  async mounted() {
    const response = await electionApi.getEpList()
    this.electoralEpLists = response.data.epNviResult.listak.sort((a, b) => a.sorszam - b.sorszam)
    this.showMandatum = response.data.epNviResult.mandatumSzamitott
    if (this.showMandatum) {
      this.bejutottakEpLists = JSON.parse(JSON.stringify(this.electoralEpLists))
      for (const bejutottLista of this.bejutottakEpLists) {
        let count = 0;
        bejutottLista.eredetiJeloltekSzam = bejutottLista.jeloltek.length
        console.log('ennyi: ', bejutottLista.jeloltek.length)
        console.log(bejutottLista)
        bejutottLista.jeloltek = bejutottLista.jeloltek.filter(() => {
          count++;

          return count <= bejutottLista.eredmeny.mandatumSzamitas
        })
        /* RANDOM */
      }
      this.bejutottakEpLists = this.bejutottakEpLists.filter(list => list.jeloltek.length > 0)
    }
  },
  methods: {
    getImageSrc(fenykep) {
      return 'https://assets.telex.hu/images/2024/valasztas/ep/jeloltek/' + fenykep + '.jpg'
    },
  },
}
</script>
<style lang="scss">
.ep__container {
  font-family: Inter, Helvetica, Arial, sans-serif;
  position: relative;
  padding: 15px 0 40px 0;
  &__subtitle {
    font-size: 1.2rem;
    font-weight: var(--bold);
    color: var(--color-text);
    margin: 20px 0 10px 0;
    @media screen and (min-width: 768px) {
      margin: 30px 0 10px 0;
    }
  }
  .embed-me-container {
    bottom: 6px;
  }
}

</style>
