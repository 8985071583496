export default {
  props: {
    shouldPushRoute: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      slug: null,
    }
  },
  methods: {
    setRoute(base, path) {
      if (!this.shouldPushRoute) {
        this.slug = path
        return
      }
      const newPath = base.endsWith('/') ? base + path : base + '/' + path;
      if (this.$router.currentRoute.fullPath !== newPath) {
        let message = {
          type: 'route-push',
          sourceAddress: window.location.href,
          path: newPath,
        };
        window.parent.postMessage(message, '*')
        this.$router.push({
          path: newPath,
        })
      }
    },
  },
}
