function getTypedImage(src, type, breakpoint) {
  if (!src) {
    return null
  }

  if (!type) {
    return src
  }

  const url = new URL(src)

  /*
   * Ha van már asset típusa, pl.:
   * https://assets.telex.hu/cikkek/123_cikktorzs.jpg
   */
  const match = url.pathname.match(/([^/]*)\/*$/)[1].match(/.*_(.*).(jpg|jpeg|gif|png)$/)
  if (match && match[1]) {
    return url.origin + url.pathname.replace(new RegExp('_' + match[1]), '_' + type + (breakpoint ? ':' + breakpoint : ''))
  }

  /*
   * Ha nincs még asset típusa, pl.:
   * https://assets.telex .hu/cikkek/123.jpg
   */
  const fileNameMatch = url.pathname.match(/([^/]*)\/*$/)[1].match(/(.*)(.jpg|.jpeg|.gif|.png)$/)
  return url.origin + url.pathname.replace(fileNameMatch[1], fileNameMatch[1] + '_' + type + (breakpoint ? ':' + breakpoint : ''))
}

function getSrcSet(src) {
  const srcsetItems = []

  for (let i = 1; i++ < 4;) {
    srcsetItems.push(src.replace(/(.(png|jpeg|jpg|gif))$/g, '@' + i + 'x$1 ' + i + 'x'))
  }
  srcsetItems.push(src.replace(/(.(png|jpeg|jpg|gif))$/g, '@1.25x$1 1.25x'))

  return srcsetItems.join(', ')
}

export { getTypedImage, getSrcSet }
