<template>
  <div :class="{'ov2024-list--big' : isMapBig }" class="ov2024-list">
    <map-view
        :base-path="basePath"
        :theme="theme"
        :mobile="false"
        :inactive="!!inactive"
        :election="election"
        :ep-base-path="epBasePath"
        :onkormanyzati-base-path="onkormanyzatiBasePath"
        @map-size-changed="handleMapSizeChange" />
  </div>
</template>
<script>
import Map from "@/components/Map.vue";

export default {
  name: 'CommonMap',
  components: {
    MapView: Map,
  },
  props: {
    election: {
      type: String,
      default: 'onkormanyzati',
    },
    basePath: {
      type: String,
      default: '/ep/terkep',
    },
    theme: {
      type: String,
      default: 'light',
    },
    inactive: {
      type: String,
      defatult: '',
    },
    epBasePath: {
      type: String,
      default: '/ep/terkep',
    },
    onkormanyzatiBasePath: {
      type: String,
      default: '/onkormanyzati/terkep',
    },
  },
  data() {
    return {
      isMapBig: false,
    }
  },
  methods: {
    handleMapSizeChange(value) {
      this.isMapBig = value;
      if (this.$store) {
        this.$store.commit('isMapBig', value)
      } else {
        window.parent.postMessage({ type: 'isMapBig', value, sourceAddress: window.location.href })
      }
    },
  },
}
</script>
