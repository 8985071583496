<template>
  <div>
    <button
        :aria-expanded="isOpen"
        :aria-controls="`collapse${_uid}`"
        class="accordion__head"
        @click="toggleAccordion()"
    >
      <slot name="title" />
      <span class="accordion__icon">
        <i
            class="mdi"
            :class="{'mdi-chevron-up': isOpen, 'mdi-chevron-down': !isOpen}"
        />
      </span>
    </button>
    <div v-show="isOpen" :id="`collapse${_uid}`">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.open,
    }
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>
<style lang="scss">
</style>
