<template>
  <div class="ov2024-list__search__items">
    {{ getTelepulesNev }}
  </div>
</template>

<script>
export default {
  name: 'OvTelepulesItem',
  props: {
    item: { type: Object, required: true },
    searchText: { type: String, default: null },
  },
  computed: {
    getTelepulesNev() {
      return this.item.nev + ((this.item.isMegye && !this.item.isFovaros) ? ' vármegye' : '')
    },
  },
}
</script>
