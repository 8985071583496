export const config = {
  embed: {
    showPoweredBy: process.env.VUE_APP_SHOW_POWERED_BY || false,
    showEmbedMe: process.env.VUE_APP_SHOW_EMBED_ME || true,
    hideFullScreen: process.env.VUE_APP_HIDE_FULLSCREEN || false,
    showMores: process.env.VUE_APP_SHOW_MORES || false,
    embedToBaseUrl: process.env.VUE_APP_EMBED_TO_BASE_URL || 'https://valasztas-2024.telex.hu',
  },
  api: {
    backendUrl: process.env.VUE_APP_API_BACKEND_URL !== null ? process.env.VUE_APP_API_BACKEND_URL : "https://telex.hu",
    electionApiPath: '/api/valasztas/2024',
  },
  map: {
    geojsonsPathPrefix: process.env.VUE_APP_GEOJSONS_PATH_PREFIX || process.env.VUE_APP_GEOJSONS_PATH_PREFIX === ''
      ? process.env.VUE_APP_GEOJSONS_PATH_PREFIX : "https://valasztas-2024.telex.hu",
  },
  counties: [
    { nev: 'Budapest', slug: 'budapest', isFovaros: true },
    { nev: 'Bács-Kiskun', slug: 'bacs-kiskun' },
    { nev: 'Baranya', slug: 'baranya' },
    { nev: 'Békés', slug: 'bekes' },
    { nev: 'Borsod-Abaúj-Zemplén', slug: 'borsod-abauj-zemplen' },
    { nev: 'Csongrád-Csanád', slug: 'csongrad-csanad' },
    { nev: 'Fejér', slug: 'fejer' },
    { nev: 'Győr-Moson-Sopron', slug: 'gyor-moson-sopron' },
    { nev: 'Hajdú-Bihar', slug: 'hajdu-bihar' },
    { nev: 'Heves', slug: 'heves' },
    { nev: 'Jász-Nagykun-Szolnok', slug: 'jasz-nagykun-szolnok' },
    { nev: 'Komárom-Esztergom', slug: 'komarom-esztergom' },
    { nev: 'Nógrád', slug: 'nograd' },
    { nev: 'Pest', slug: 'pest' },
    { nev: 'Somogy', slug: 'somogy' },
    { nev: 'Szabolcs-Szatmár-Bereg', slug: 'szabolcs-szatmar-bereg' },
    { nev: 'Tolna', slug: 'tolna' },
    { nev: 'Vas', slug: 'vas' },
    { nev: 'Veszprém', slug: 'veszprem' },
    { nev: 'Zala', slug: 'zala' },
  ],
  topCities: [
    { nev: 'Békéscsaba', slug: 'bekes/bekescsaba', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Budapest', slug: 'budapest', isMegye: true, isVaros: true, isFovaros: true },
    { nev: 'Debrecen', slug: 'hajdu-bihar/debrecen', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Dunaújváros', slug: 'fejer/dunaujvaros', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Eger', slug: 'heves/eger', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Érd', slug: 'pest/erd', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Győr', slug: 'gyor-moson-sopron/gyor', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Hódmezővásárhely', slug: 'csongrad-csanad/hodmezovasarhely', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Kaposvár', slug: 'somogy/kaposvar', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Kecskemét', slug: 'bacs-kiskun/kecskemet', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Miskolc', slug: 'borsod-abauj-zemplen/miskolc', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Nagykanizsa', slug: 'zala/nagykanizsa', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Nyíregyháza', slug: 'szabolcs-szatmar-bereg/nyiregyhaza', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Pécs', slug: 'baranya/pecs', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Salgótarján', slug: 'nograd/salgotarjan', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Sopron', slug: 'gyor-moson-sopron/sopron', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Szeged', slug: 'csongrad-csanad/szeged', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Székesfehérvár', slug: 'fejer/szekesfehervar', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Szekszárd', slug: 'tolna/szekszard', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Szolnok', slug: 'jasz-nagykun-szolnok/szolnok', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Szombathely', slug: 'vas/szombathely', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Tatabánya', slug: 'komarom-esztergom/tatabanya', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Veszprém', slug: 'veszprem/veszprem', isMegye: false, isVaros: true, isFovaros: false },
    { nev: 'Zalaegerszeg', slug: 'zala/zalaegerszeg', isMegye: false, isVaros: true, isFovaros: false },
  ],
  partyColors: {
    fidesz: { name: 'Fidesz', color: '#ec6909', ellenzek: false },
    kdnp: { name: 'KDNP', color: '#ec6909', ellenzek: false },
    dk: { name: 'DK', color: '#1b7bbb', ellenzek: true },
    párbeszéd: { name: 'Párbeszéd', color: '#85cc7a', ellenzek: true },
    lmp: { name: 'LMP', color: '#fbbb3e', ellenzek: true },
    'mi hazánk': { name: 'Mi Hazánk', color: '#698e2d', ellenzek: false },
    momentum: { name: 'Momentum', color: '#7e68ac', ellenzek: true },
    jobbik: { name: 'Jobbik', color: '#1f416b', ellenzek: true },
    mkkp: { name: 'MKKP', color: '#202020', ellenzek: false },
    mszp: { name: 'MSZP', color: '#dc1c28', ellenzek: true },
    'tisza párt': { name: 'Tisza Párt', color: '#ededed', ellenzek: false },
    ellenzék: { name: 'Ellenzék', color: '#33777c', ellenzek: true },
    független: { name: 'Független', color: '#9d9d9d', ellenzek: false },
  },
  participations: {
    bars: [
      {
        title: "Önkormányzati és EP-választás 2024",
        key: "2024o",
      },
      {
        title: "Önkormányzati választás 2019",
        key: "2019o",
        data: [
          {
            idopont: "07:00",
            szazalek: "1.12",
          },
          {
            idopont: "09:00",
            szazalek: "7.2",
          },
          {
            idopont: "11:00",
            szazalek: "18.25",
          },
          {
            idopont: "13:00",
            szazalek: "26.6",
          },
          {
            idopont: "15:00",
            szazalek: "34.69",
          },
          {
            idopont: "17:00",
            szazalek: "42.69",
          },
          {
            idopont: "18:30",
            szazalek: "47.2",
          },
          {
            idopont: "19:00",
            szazalek: "48.58",
          },
        ],
      },
      {
        title: "Európai parlamenti választás 2019",
        key: "2019e",
        data: [
          {
            idopont: "07:00",
            szazalek: "1.48",
          },
          {
            idopont: "09:00",
            szazalek: "7.78",
          },
          {
            idopont: "11:00",
            szazalek: "17.16",
          },
          {
            idopont: "13:00",
            szazalek: "24.01",
          },
          {
            idopont: "15:00",
            szazalek: "30.52",
          },
          {
            idopont: "17:00",
            szazalek: "37.06",
          },
          {
            idopont: "18:30",
            szazalek: "41.74",
          },
          {
            idopont: "19:00",
            szazalek: "43.58",
          },
        ],
      },
      {
        title: "Önkormányzati választás 2006 *",
        key: "2006o",
        data: [
          {
            idopont: "07:00",
            szazalek: "1.12",
          },
          {
            idopont: "09:00",
            szazalek: "7.7",
          },
          {
            idopont: "11:00",
            szazalek: "18.53",
          },
          {
            idopont: "13:00",
            szazalek: "26.98",
          },
          {
            idopont: "15:00",
            szazalek: "34.77",
          },
          {
            idopont: "17:00",
            szazalek: "46.53",
          },
          {
            idopont: "18:30",
            szazalek: "46.53",
          },
          {
            idopont: "19:00",
            szazalek: "53.12",
          },
        ],
      },
    ],
    date: "2024-06-09",
  },
  hours: ["07:00", "09:00", "11:00", "13:00", "15:00", "17:00", "18:30", "19:00"],
}
