const megyeiJoguVarosok = ["baja", "bekescsaba", "debrecen", "dunaujvaros", "eger", "erd", "esztergom", "gyor", "hodmezovasarhely", "kaposvar", "kecskemet", "miskolc", "nagykanizsa", "nyiregyhaza", "pecs", "salgotarjan", "sopron", "szeged", "szekszard", "szekesfehervar", "szolnok", "szombathely", "tatabanya", "veszprem", "zalaegerszeg"]

export default {
    megyeiJoguVarosok,
    layers: [
        {
            "id": "europe-layer",
            "type": "fill",
            "source": "europe",
            "paint": {
                "fill-color": "#e7e7e7",
                "fill-opacity": 1,
            },
            "before": {dark: "Path outline", light: "boundary_state"},
        },
        {
            "id": "megye-layer",
            "type": "fill",
            "source": "megyek",
            "paint": {
                "fill-color": "#E6E9E8",
                "fill-opacity": .3,
            },
            "before": {dark: "Path outline", light: "highway_motorway_bridge_casing"},
        },
        {
            "id": "telepules-layer",
            "type": "fill",
            "source": "telepulesek",
            "paint": {
                "fill-color": "#FFFFFF",
                "fill-opacity": .3,
            },
            "filter": [
                "all",
                ["==", "1", "0"],
            ],
            "before": {dark: "Path outline", light: "highway_motorway_bridge_casing"},
        },
        {
            "id": "megyei-jogu-layer-shadow",
            "type": "fill",
            "source": "megyeijogu",
            "paint": {
                "fill-color": "#F2F3F0",
                "fill-opacity": 1,
            },
            "before": {dark: "Path outline", light: "highway_motorway_bridge_casing"},
        },
        {
            "id": "megyei-jogu-layer",
            "type": "fill",
            "source": "megyeijogu",
            "paint": {
                "fill-color": "#C7CDD2",
                "fill-opacity": .4,
            },
            "before": {dark: "Path outline", light: "highway_motorway_bridge_casing"},
        },
        {
            "id": "telepules-outline",
            "type": "line",
            "source": "telepulesek",
            "paint": {
                "line-color": [
                    "case",
                    ["boolean", ["feature-state", "selected"], false],
                    "white",
                    ["boolean", ["feature-state", "hover"], false],
                    "white",
                    "lightgray",
                ],
                "line-width": [
                    "case",
                    ["boolean", ["feature-state", "selected"], false],
                    2.5,
                    ["boolean", ["feature-state", "hover"], false],
                    1.5,
                    .5,
                ],
                "line-opacity": [
                    "case",
                    ["boolean", ["feature-state", "selected"], false],
                    1,
                    ["boolean", ["feature-state", "hover"], false],
                    .6,
                    .3,
                ],
            },
            "filter": [
                "all",
                [
                    "==",
                    "1",
                    "0",
                ],
            ],
            "before": {dark: "place_village", light: "place_other"},
        },
        {
            "id": "megye-outline",
            "type": "line",
            "source": "megyek",
            "paint": {
                "line-color": [
                    "case",
                    ["boolean", ["feature-state", "selected"], false],
                    "white",
                    ["boolean", ["feature-state", "hover"], false],
                    "white",
                    "lightgray",
                ],
                "line-width": [
                    "case",
                    ["boolean", ["feature-state", "selected"], false],
                    3,
                    ["boolean", ["feature-state", "hover"], false],
                    1.5,
                    .5,
                ],
                "line-opacity": [
                    "case",
                    ["boolean", ["feature-state", "selected"], false],
                    1,
                    ["boolean", ["feature-state", "hover"], false],
                    .6,
                    .3,
                ],
            },
            "before": {dark: "place_village", light: "place_other"},
        },
        {
            "id": "megyei-jogu-outline",
            "type": "line",
            "source": "megyeijogu",
            "paint": {
                "line-color": [
                    "case",
                    ["boolean", ["feature-state", "selected"], false],
                    "white",
                    ["boolean", ["feature-state", "hover"], false],
                    "white",
                    "lightgray",
                ],
                "line-width": [
                    "case",
                    ["boolean", ["feature-state", "selected"], false],
                    3.5,
                    ["boolean", ["feature-state", "hover"], false],
                    1.5,
                    .5,
                ],
                "line-opacity": [
                    "case",
                    ["boolean", ["feature-state", "selected"], false],
                    1,
                    ["boolean", ["feature-state", "hover"], false],
                    .8,
                    .6,
                ],
            },
            "before": {dark: "place_village", light: "place_other"},
        },
        {
            "id": "szavazokorok-circle",
            "type": "circle",
            "source": "szavazokorok",
            "maxzoom": 18,
            "minzoom": 5,
            "filter": [
                '==', '0', '1',
            ],
            "paint": {
                "circle-color": "#468",
                "circle-opacity": [
                    "interpolate",
                    ["exponential", 0.3],
                    ["zoom"],
                    5,
                    0,
                    14,
                    0.5,
                ],
                "circle-radius": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    5,
                    1,
                    7,
                    1,
                    15,
                    4,
                ],
            },
            "before": {dark: "place_village", light: "place_other"},
        },
        {
            "id": "szavazokorok",
            "type": "symbol",
            "source": "szavazokorok",
            "minzoom": 8,
            "filter": ["==", ["get", "telepulesSlug"], "alma"],
            "layout": {
                "icon-image": "szavazokor-marker",
                "icon-size": 0.2,
                "icon-anchor": "bottom",
            },
            "before": {dark: "place_village", light: "place_other"},
        },
    ],
    updateLayerColor: (layer, theme, election) => {
        if (layer.type === "line") {
            const selectedColor = theme === 'dark' ? 'white' : '#468';
            const defaultColor = theme === 'dark' ? '#444' : '#468';
            layer.paint["line-color"][2] = selectedColor
            layer.paint["line-color"][4] = selectedColor
            layer.paint["line-color"][5] = defaultColor
        }
        if (layer.id === 'europe-layer') {
            switch (election.name) {
                case 'onkormanyzati':
                    layer.filter = ['!=', "NAME", "Hungary"];
                break
                default: break;
            }
        }
        return layer
    },
}
