import { config } from '@/config'

export default {
    methods: {
        getPartyColor(jlcs) {
            if (typeof jlcs === "string") {
                jlcs = jlcs.split('-').map(e => e.trim().toLowerCase())
            } else {
                jlcs = (jlcs?.tagok || []).map(e => e.rovidNev.toLowerCase())
            }
            const found = jlcs.filter(e => config.partyColors[e])
            if (found.includes('fidesz')) {
                return config.partyColors['fidesz'].color
            }
            if (found.length === 1) {
                return config.partyColors[found[0]].color
            }
            if (found.length > 1) {
                return config.partyColors['ellenzék'].color
            }
            return config.partyColors['független'].color
        },
    },
}