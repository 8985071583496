<template>
  <div v-if="showEmbedMe" class="embed-me-container">
    <modal v-if="showModal" @close="closeModal">
      <template v-slot:header>
<!--        <object type="image/svg+xml" data="/assets/images/share.svg" width="20" height="20"></object> Beágyazás-->
      </template>
      <template v-slot:body>
        <div class="embed-me-modal-content">
          <button @click="copyToClipboard" :class="{ clicked: copyClicked }" class="copy-to-clipboard-btn">
            <div class="primary text">Vágólapra másolás</div>
            <div class="secondary text">Sikeres másolás!</div>
          </button>
          <div class="embed-code">
            {{ embedCode }}
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button class="modal-ok" @click="closeModal">Bezárás</button>
      </template>
    </modal>
    <div class="ov2024-list__map__info">
      <div class="ov2024-list__map__info__telex">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="0"
            height="100%"
            viewBox="0 0 160 51"
            fill-rule="evenodd"
            stroke-linejoin="round"
            stroke-miterlimit="2"
            class="telex"
        >
          <path d="M68.503 42.525V9.939c0-4.677-1.252-5.991-3.759-6.868L82.063.002v42.523c0 4.677 3.243 7.599 3.243 7.599H65.26s3.243-2.922 3.243-7.599zm-51.11-27.033h10.029l-.006-3.109-10.023 3.109zm4.569 29.662c-2.875 0-4.569-1.607-4.569-5.113V0L0 15.488c2.117 0 3.831 1.702 3.831 3.798V39.89c0 8.182 3.831 11.106 10.244 11.106 5.011 0 10.391-3.215 13.929-7.891 0 .006-2.46 2.049-6.043 2.049h.001zm40.947-18.265c.442-9.499-4.717-15.928-14.592-15.928-11.94 0-19.456 9.352-19.456 20.751C28.861 41.794 34.536 51 46.475 51c8.623 0 13.192-4.896 16.435-10.74 0 0-3.33 4.456-9.949 4.456-7.59 0-10.317-6.575-10.54-17.827V25.72c0-6.503.81-13.735 5.601-13.735 4.127 0 4.348 4.86 3.685 13.261l-9.286 1.643H62.91h-.001zm58.876 0c.442-9.499-4.717-15.928-14.592-15.928-11.94 0-19.456 9.352-19.456 20.751C87.736 41.794 93.412 51 105.35 51c8.623 0 13.192-4.896 16.435-10.74 0 0-3.33 4.456-9.949 4.456-7.59 0-10.317-6.575-10.54-17.827V25.72c0-6.503.81-13.735 5.601-13.735 4.127 0 4.348 4.86 3.685 13.261l-9.286 1.643h20.489zm38.217 23.235h-19.458s2.58-2.703.884-5.699l-5.748-9.863s-5.527 10.375-5.527 12.859c0 2.703 1.989 2.703 1.989 2.703h-10.907l14.446-15.562-7.811-13.445c-2.654-4.603-6.339-9.28-6.339-9.28h19.456s-2.58 2.703-.884 5.699l5.306 9.061s4.79-9.571 4.79-12.056c0-2.703-1.989-2.703-1.989-2.703h10.761L145.41 26.597l8.253 14.247c2.652 4.603 6.339 9.28 6.339 9.28z" fill-rule="nonzero" />
        </svg>
        <button @click="openModal">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 22 16"
              fill-rule="evenodd" stroke-linejoin="round"
              stroke-miterlimit="2"
          >
            <path d="M6.27 4.942l-3.08 3.08 3.08 3.08-1.54 1.54L0 8.022l4.73-4.73.77.77.77.88zm10.23-.88l.77-.77L22 8.022l-.99.88-3.74 3.74-1.54-1.54 3.08-3.08-3.08-3.08.77-.88zM7.74 15.443L12.145 0l2.115.603-4.404 15.444-2.116-.604z" />
          </svg>
          <span> beágyazás</span>
        </button>
      </div>
      <span class="ov2024-list__map__info__disclaimer">
          A megjelenített információk tájékoztató jellegűek.
        </span>
    </div>
  </div>
</template>
<script>
import { config } from '@/config'
import Modal from '@/components/sub-components/Modal'

export default {
  components: { Modal },
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  computed: {
    embedUrl() {
      return config.embed.embedToBaseUrl + this.path
    },
    embedCode() {
      return `<iframe src='${this.embedUrl}' id='${this.iframeId}' style="width: 100%" allow="geolocation"></iframe>`
          + `<script>`
          + `  window.addEventListener('message', function(e) {`
          + `    if (e.data.type === 'iframe-size' && e.data.sourceAddress === '${this.embedUrl}') {`
          + `      const ifr = document.getElementById('${this.iframeId}');`
          + `      ifr.style.height = e.data.height + 'px';`
          + `    }`
          + `  }, false);`
          + `<\/script>`; // Escaping the closing script tag
    },
  },
  data() {
    return {
      showEmbedMe: config.embed.showEmbedMe,
      iframeId: null,
      showModal: false,
      copyClicked: false,
    }
  },
  async mounted() {
    this.iframeId = await this.getIframeId()
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },
    copyToClipboard() {
      this.copyClicked = true
      navigator.clipboard.writeText(this.embedCode.replace(/\s+/g, ' '))
      setTimeout(() => this.copyClicked = false, 1500)
    },
    async getIframeId() {
      const encoder = new TextEncoder();
      const data = encoder.encode(this.path);
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return 'telex-embed-' + hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    },
  },
}
</script>
<style lang="scss">
.copy-to-clipboard-btn {
  overflow: hidden;
  width: 140px;
  height: 34px;
  padding: 12px 10px;
  border-radius: 25px;
  transition: background-color 0.7s;
  position: relative;

  &.clicked {
    background-color: rgb(0, 136, 85);
    .primary {
      top:-60px;
    }

    .secondary {
      top: 10px;
    }
  }

  .text {
    transition: top 0.7s;
    width: 140px;
    height: 34px;
    position: absolute;
  }

  .primary {
    left: 0;
    top: 10px;
  }

  .secondary {
    left: 0;
    top: 60px;
  }
}
</style>
