<template>
  <div class="list__faq">
    <div class="list__faq__container">
      <div class="list__faq__content">
        <accordion
            v-for="(current, index) in faq.filter(f => f.t === 'u')"
            :key="index"
            class="accordion"
        >
          <template #title>
            <div class="accordion__title">
              <div v-html="current.q" />
            </div>
          </template>
          <template #content>
            <div class="accordion__content">
              <div v-html="current.a" />
            </div>
          </template>
        </accordion>
      </div>
      <div class="list__faq__content">
        <accordion
            v-for="(current, index) in faq.filter(f => f.t === 'd')"
            :key="index"
            class="accordion"
        >
          <template #title>
            <div class="accordion__title">
              <div v-html="current.q" />
            </div>
          </template>
          <template #content>
            <div class="accordion__content">
              <div v-html="current.a" />
            </div>
          </template>
        </accordion>
      </div>
    </div>
  </div>
</template>
<script>
import Accordion from '@/components/sub-components/Accordion'
import sizeReporter from '@/mixins/sizeReporter'

export default {
  mixins: [sizeReporter],
  components: {
    Accordion,
  },
  computed: {
    faq() {
      return [
        { t: 'u', q: 'Mi az az Európai Parlament és egyáltalán mit csinál?', a: 'Az Európai Parlament az Európai Unió egyetlen olyan intézménye, amelynek a tagjait - már 1979 óta - közvetlenül választják. A képviselő-testület egyik legfontosabb feladata, hogy döntsön az uniós jogszabályok elfogadásáról, de nem egyedül, hanem a tagállami miniszterekkel együtt az Európai Unió Tanácsában. A rendes jogalkotási eljárásnál mindkét intézmény társjogalkotó egyenlő jogokkal. Általában ezt a módszert használják, de témától is függően ez nem mindig van így, például az uniós külpolitikánál csak a tagállamok döntenek egyhangúlag. A két testületnek kell elfogadnia az éves uniós költségvetést, és a többéves keretbüdzséknél az EP olyan, mintha a 28. tagállam lenne.<br><br>Az Európai Parlament megszavazhat saját állásfoglalásokat is, amiknek ugyan nincs jogi következményük, de kifejezik a testület politikai véleményét. Jogszabályt viszont nem kezdeményezhet, ez az Európai Bizottság feladata.<br><br>Az Európai Parlament ellenőrzi az uniós intézmények, főleg az Európai Bizottság munkáját, és ezt a testületet, valamint a vezetőjét is az EP-képviselők választják meg az európai parlamenti voksolások után.' },
        { t: 'u', q: 'Mikor vannak az európai parlamenti választások?', a: 'A következő európai parlamenti választásokat 2024. június 6-9-én rendezik. Azért több napon keresztül, mert eltér, hogy melyik tagállamban melyik napon szoktak választani.' },
        { t: 'u', q: 'Milyen gyakran vannak európai parlamenti választások?', a: 'Ötévente.' },
        { t: 'u', q: 'Mikor szavazunk Magyarországon?', a: 'Szokás szerint vasárnap, azaz 2024. június 9-én. A választási kampány 2024. április 20-án (szombat 0 órától) kezdődik és 2024. június 9-én (vasárnap) 19.00 óráig tart.' },
        { t: 'u', q: 'Kire vagy mire szavazhatunk?', a: 'Listákra, amelyeket akár egy vagy több, közösen induló párt állíthat, ha elég aláírást gyűjtöttek össze.' },
        { t: 'u', q: 'Hány képviselőt választunk?', a: 'Magyarországról 21 képviselőt választunk az Európai Parlamentbe. A testületnek most 705 tagja van, de 720-at fognak választani, hogy tartsák az arányokat a tagállamok lakosságának változásai miatt. (Magyarország a legutóbbi felülvizsgálatnál nem kapott plusz helyet.)' },
        { t: 'u', q: 'Ki szavazhat Magyarországon?', a: 'Az EP-választáson választójoggal rendelkezik minden, Magyarország területén lakóhellyel rendelkező nagykorú magyar állampolgár, valamint az Európai Unió más tagállamának magyarországi lakóhellyel rendelkező választópolgára, amennyiben nyilatkozatot tesz arról, hogy Magyarországon akar szavazni; továbbá azok a nagykorú magyar állampolgárok, akiknek sem Magyarországon, sem az Európai Unió más tagállamában sincsen lakcímük.' },
        { t: 'u', q: 'Mi van, ha külföldről szavaznék?', a: 'Magyarországi lakcímmel lehet szavazni nagykövetségen vagy konzulátuson. Ezt május 31-én délután 4 óráig lehet kérni.<br><br>Ha valakinek nincs magyarországi lakcíme, nem mindegy, hogy ilyenkor uniós tagállamból szavazna vagy sem. Ha olyan országban van a lakóhelye, amely nem EU-tag, akkor levélben szavazhat, de uniós országból nem szavazhat magyarországi listára magyarországi lakcím híján.' },
        { t: 'u', q: 'Mi van, ha külhoni magyar vagyok?', a: 'Külhoni magyar állampolgár csak akkor szavazhat, ha az EU egyetlen más tagállamában sincsen lakcíme (például Szerbia vagy Ukrajna, de nem mondjuk Szlovákia vagy Románia területén van lakcíme). Ilyenkor <a href="https://www.valasztas.hu/regisztracio" target="_blank">regisztrálni</a> kell május 15-én délután 4 óráig az Nemzeti Választási Irodánál, ami utána küld egy szavazási levélcsomagot.\n' },
        { t: 'd', q: 'Hol kell szavaznom?', a: 'Alapesetben mindenki a lakóhelye szerinti szavazóhelyiségben szavazhat, de kérhet mozgóurnát, átjelentkezhet vagy kérheti, hogy nagykövetségen szavazzon. <a href="https://vtr.valasztas.hu/ep2024/valasztopolgaroknak/szavazasi-informaciok/szavazohelyiseg-kereses" target="_blank">Itt</a> lehet keresni a szavazóhelyiségek között.' },
        { t: 'd', q: 'Mit tegyek, ha más szavazókörben akarok szavazni, mint ahol lakom?', a: 'Bármelyik másik szavazókörben lehet szavazni, de ehhez át kell jelentkezni <a href="https://mo.hu/szuf_ugyleiras?id=953f824b-c0f9-4eb9-851c-29fad42ee06f&_n=atjelentkezes_az_europai_parlamenti__a_helyi_onkormanyzati__a_nemzetisegi_onkormanyzati_altalanos_valasztason__az_idokozi_helyi_onkormanyzati_valasztason__a_helyi_nepszavazason" target="_blank">interneten</a> vagy <a href="https://www.valasztas.hu/documents/20182/6162351/%C3%81tjelentkez%C3%A9si+k%C3%A9relem.pdf/76d47901-d7a0-77b2-247e-9d89c0c84a43" target="_blank">papíron</a> bármely <a href="https://www.valasztas.hu/elerhetosegek" target="_blank">helyi választási irodában</a> (személyesen, esetleg meghatalmazotton keresztül). Átjelentkezést május 31-én 16 óráig lehet kérni, de ez visszavonható június 7-én 10 óráig.' },
        { t: 'd', q: 'Mit tegyek, ha beteg vagyok vagy fogyatékkal élek és ez akadályozna a szavazásban?', a: 'Aki egészségi állapota vagy fogyatékosság miatt nem tud elmenni szavazni, mozgóurnát <a href="https://www.valasztas.hu/kerelmek-magyarorszagi-lakcimmel-rendelkezoknek" target="_blank">igényelhet</a> június 9-én 12 óráig. Május 31-én délután 4-ig lehet Braille-írásos szavazósablont <a href="https://www.valasztas.hu/kerelmek-magyarorszagi-lakcimmel-rendelkezoknek" target="_blank">kérni</a>, június 5-én délután 4-ig pedig akadálymentes szavazóhelyiséget.' },
        { t: 'd', q: 'Miért kérhetik a szavazás előtt az aláírásomat?', a: 'Az európai parlamenti választáson az a párt indulhat, amely összegyűjt 20 ezer aláírást május 3-án délután 4 óráig, így április 20-tól addig lehet számítani standokra, házról házra járó aláírásgyűjtőkre.' },
        { t: 'd', q: 'Hol és hogyan kérhetik az aláírásomat?', a: 'Aláírást <a href="https://www.valasztas.hu/europai-parlamenti-valasztasok2" target="_blank">bizonyos kivételekkel</a> bárhol lehet gyűjteni (tilos például munkaidőben a munkahelyen). A gyűjtők senkik sem zaklathatnak, nem adhatnak vagy ígérhetnek előnyt.' },
        { t: 'd', q: 'Honnan tudjam, hogy tényleg erre kérik az aláírásomat?', a: 'Az ajánlóíveket a Nemzeti Választási Iroda adja ki, rajtuk van a jelölő szervezet(ek) neve és egy egyedi azonosító. Az íveket tilos fénymásolni. Az aláírás mellé név és a személyi azonosító vagy a személyi/útlevél/jogosítvány száma kell. Ha valami a leírtakból nem stimmel, már lehet is kezdeni gyanakodni.' },
        { t: 'd', q: 'Mire érdemes még figyelni az aláírásgyűjtésnél?', a: 'Minden szükséges adatot meg kell adni és pontosan. Máskülönben (például ha más a viselt nevünk, mint a születési és utóbbit adjuk meg) akár az ajánlóíven szereplő összes ajánlást érvényteleníthetik.' },
        { t: 'd', q: 'Meddig lehet szavazni, és mikortól jönnek az eredmények?', a: 'Magyarországon az EP-választáson a szavazás majd 2024. június 9-én 6.00 órától 19.00 óráig tart. Azok még szavazhatnak, akik 19 órakor már sorban állnak.<br><br>Az EP-választáson a szavazáson megjelent választók számának kivételével csak azután lehet nyilvánosságra hozni részeredményeket, ha az Európai Unió összes tagállamában befejeződött a szavazás. Mivel Olaszországban 23 órakor zárnak a szavazókörök, ezért onnantól lesznek ismertek a magyar eredmények is.' },
        { t: 'd', q: 'Mi történik majd a szavazás után?', a: 'Az Európai Parlamentben megválasztott képviselők “politikai csoportokat”, azaz frakciókat alkotnak (ezeknek legalább 23 képviselőből kell állniuk a tagállamok negyedéből). Az első, tervek szerint júliusi ülésen megválasztják az Európai Parlament elnökét.<br><br>A következő, szeptemberi ülésükön voksolnak az Európai Bizottság vezetőjéről, akit az állam- és kormányfők jelölnek, de figyelembe kell venniük az EP-választás eredményét és a nagyobb frakciók már előre megneveztek “csúcsjelölteket”. A képviselők később a teljes Európai Bizottságról is döntenek, miután meghallgatták a leendő tagokat (minden egyes tagállami kormány jelölhet biztost, de a bizottsági elnök osztja be, hogy milyen területért feleljenek)' },
      ]
    },
  },
}
</script>
<style lang="scss">
.list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include xl {
    margin: 0 auto;
    width: 1120px;
  }
  &__faq {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin: 0 0 40px 0;
    @include m {
      margin: 0 0 60px 0;
    }
    @include l {
      margin: 0;
      // grid-template-columns: 1fr 1fr;
      // grid-gap: 60px;
      // align-items: start;
    }
    &__container {
      display: grid;
      place-content: center;
      width: 100%;
      grid-template-columns: 1fr;
      @include l {
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 60px;
      }
      @include xl {
        grid-gap: 0 90px;
      }
      &__solo {
        grid-template-columns: 1fr;
      }
      &__title {
        font-size: 1.2rem;
        font-weight: var(--bold);
        margin: 0 auto 30px auto;
        color: var(--black);
      }
    }
    .accordion {
      width: 100%;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>

