<template>
  <div>
    <div v-if="!collapsed" class="ep24-mandates__parties__participation">
      Részvétel: {{ turnoutPercent }}%
    </div>
    <div class="ep24-mandates__parties">
      <template v-for="(group, index) in groupDistribution">
        <div :key="'ep-modates-parties-title' + index" class="ep24-mandates__parties__title">
          {{ group.groupInfo.longName.charAt(0).toUpperCase() + group.groupInfo.longName.slice(1) }}
          ({{ group.groupInfo.acronym }})
        </div>
        <div :key="'ep-modates-parties-results' + index" class="ep24-mandates__parties__results">
          <div
              v-for="index1 in group.seatsTotal"
              :key="index1"
              class="ep24-mandates__parties__seat"
              :style="{ backgroundColor: group.groupInfo.color }"
          />
        </div>
      </template>
      <div v-if="seatsTotal - sumSeats > 0">
        <div class="ep24-mandates__parties__title">
          Még ki nem osztott mandátumok
        </div>
        <div class="ep24-mandates__parties__results">
          <div
              v-for="index1 in seatsTotal - sumSeats"
              :key="index1"
              class="ep24-mandates__parties__seat"
              :style="{ backgroundColor: 'salmon' }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    groupDistribution: {
      type: Array,
      default: () => [],
    },
    seatsTotal: {
      type: Number,
      default: 0,
    },
    turnoutPercent: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    sumSeats() {
      return this.groupDistribution.reduce((accumulator, obj) => accumulator + obj.seatsTotal, 0)
    },
  },
}
</script>
