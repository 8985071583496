import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router"
import EpList from '@/components/EpList'
import '@mdi/font/css/materialdesignicons.css';
import device from "vue-device-detector"
import CommonMap from "@/components/CommonMap.vue";
import OvFaq from "@/components/OvFaq.vue";
import EpFaq from "@/components/EpFaq.vue";
import EpFractions from "@/components/EpFractions.vue";
import EpMandates from "@/components/EpMandates.vue";

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(device)
const routes = [
  {
    path: "/onkormanyzati/terkep/:fullPath(.*)*",
    component: CommonMap,
    meta: {
      title: 'Önkormányzati Választás 2024 - Térkép',
    },
    props: (route) => ({
      basePath: '/onkormanyzati/terkep',
      theme: route.query.theme,
      mobile: route.query.mobile,
      inactive: route.query.inactive,
      election: 'onkormanyzati',
    }),
  },
  {
    path: "/ep/terkep/:fullPath(.*)*",
    component: CommonMap,
    meta: {
      title: 'Európai Parlamenti Választás 2024 - Térkép',
    },
    props: (route) => ({
      election: 'ep',
      basePath: '/ep/terkep',
      theme: route.query.theme,
      mobile: route.query.mobile,
      inactive: route.query.inactive,
    }),
  },
  {
    path: "/ep/listak",
    meta: {
      title: 'EP Választás 2024 - Listák',
    },
    component: EpList,
  },
  {
    path: "/onkormanyzati/faq",
    meta: {
      title: 'Önkormányzati Választás 2024 - FAQ',
    },
    component: OvFaq,
  },
  {
    path: "/ep/faq",
    meta: {
      title: 'EP Választás 2024 - FAQ',
    },
    component: EpFaq,
  },
  {
    path: "/ep/frakciok",
    meta: {
      title: 'EP Választás 2024 - Frakciók',
    },
    component: EpFractions,
  },
  {
    path: "/ep/mandatumok",
    meta: {
      title: 'EP Választás 2024 - Mandátumok',
    },
    component: EpMandates,
  },
]
const router = new VueRouter({
  routes,
  mode: 'history',
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});


Vue.mixin({
  data: function() {
    return {
    }
  },
})

const app = new Vue({
  render: h => h(App),
  router,
}).$mount('#app')

export { app, router }
