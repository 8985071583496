<template>
  <div v-if="data">
    <div class="ov2024-list__subtitle">
      {{ title }}
    </div>
    <div class="dashboard-24__progress">
      <div class="ov2024-list__barchart dashboard-24__progress__line" :style="{ 'grid-template-columns': getWidths }">
        <div
            v-for="lista in data"
            class="ov2024-list__barchart__data"
            :style="{'background': getPartyColor(lista.jeloloCsoport) }"
        >
          {{ lista.mandatumokSzama }}
        </div>
      </div>
    </div>

    <span v-for="lista in data" class="ov2024-list__card__party">
      <span
          class="ov2024-list__card__party__color ov2024-list__barchart__party__color"
          :style="{'background': getPartyColor(lista.jeloloCsoport) }"
      />
      <span class="ov2024-list__card__party__item">
        <span v-if="lista.jeloloCsoport.tagok.length">
          <span v-for="tag in lista.jeloloCsoport.tagok">{{ tag.rovidNev }}</span>
        </span>
        <template v-else>{{ lista.jeloloCsoport.nev }}</template>
      </span>
    </span>


  </div>
</template>
<script>
import partyColor from '@/mixins/partyColor'

export default {
  name: 'OvPartBar',
  mixins: [
      partyColor,
  ],
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: null,
    },
  },
  computed: {
    getWidths() {
      return (this.data.map(l => l.mandatumokSzama + 'fr') || []).join(' ')
      // return Object.values(this.values).map(e => e.szazalek+'%').join(' ')
    },
    // getValuesEvk() {
    //   const result = []
    //   this.data.forEach(d => {
    //     d.evk.forEach(e => {
    //       e.evkJeloltek.forEach(j => {
    //         if (j.bejutott === true) {
    //           const key = 'jlcs_' + j.jeloloCsoport.jlcsId
    //           if (result[key]) {
    //             result[key].darab++
    //           } else {
    //             result[key] = { jlcs: j.jeloloCsoport, darab: 1 }
    //           }
    //         }
    //       })
    //     })
    //   })
    //   return Object.values(result).sort((a, b) => b.darab - a.darab)
    // },
  },
  methods: {
  },
  mounted() {
  },
}
</script>
<style>
  .ov2024-list__barchart {
    height: auto;
    display: grid;
    grid-column-gap: 1px;
  }
  .ov2024-list__barchart__data {
    display: block;
    color: white;
    transition: width 1s;
    place-content: center;
    padding: 2px 0;
    text-align: center;
  }
  .ov2024-list__barchart__party__color {
    margin-top: 1px;
  }
</style>