<template>
<div class="map-toggle-container">
  <input type="checkbox" v-model="value" class="toggleCheckbox"/>
  <label for="toggle" class='toggleContainer' @click="onClick" >
    <span class="map-toggle-container__ep">EP</span>
    <span class="map-toggle-container__hu">HU</span>
  </label>
</div>
</template>
<script>
export default {
  props: {
    election: {
      type: String,
      default: '',
    },
  },
  computed: {
    value() {
      return this.election !== 'ep'
    },
  },
  methods: {
    onClick() {
      this.$emit('onChanged', this.election === 'ep' ? 'onkormanyzati' : 'ep')
    },
  },
}
</script>
<style lang="scss">
.map-toggle-container {
  position: absolute;
  z-index: 9;
  bottom: 10px;
  right: 50px;
  .toggleContainer {
    font-size: 12px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-weight: bold;
    color: #343434;
    cursor: pointer;
    background: #0d4f9f;
    outline: 2px solid white;
    border-radius: 30px;
    border: 2px solid #0d4f9f;
    height: 28px;
    width: 54px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);
    &:before {
      content: '';
      position: absolute;
      width: 28px;
      height: 28px;
      left: -2.5px;
      border-radius: 30px;
      background: green;
      transition: all 0.3s;
      top: 50%;
      transform: translateY(-50%);
      outline: 2px solid white;
    }
  }

  .toggleCheckbox:checked + .toggleContainer::before {
    left: calc(50% + .5px);
  }
  &__ep, &__hu {
    position: absolute;
    color: var(--white);
    font-weight: var(--bold);
    font-size: 11px;
    line-height: 1;
    z-index: 3;
    top: 50%;
    transform: translateY(-50%);
  }
  &__ep {
    left: 5px;
  }
  &__hu {
    right: 4px;
  }
  .toggleContainer div {
    z-index: 1;
  }

  .toggleCheckbox {
    display: none;
  }

  .toggleCheckbox:checked + .toggleContainer div:first-child {
    color: #b6b6b6;
    transition: color 0.3s;
  }

  .toggleCheckbox:checked + .toggleContainer div:last-child {
    color: white;
    transition: color 0.3s;
  }

  .toggleCheckbox + .toggleContainer div:first-child {
    color: white;
    transition: color 0.3s;
  }

  .toggleCheckbox + .toggleContainer div:last-child {

    color: #b6b6b6;
    transition: color 0.3s;
  }
}
</style>
