<template>
  <div class="ov2024-list__result">
    <div class="ov2024-list__result__container" v-if="selectedMemberState">
      <div class="ov2024-list__result__btn" @click="deselectMemberStates()">
      <!--<i class="mdi mdi-chevron-left"></i>-->
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="0"
          height="100%"
          viewBox="0 0 9 15"
          fill-rule="evenodd"
          stroke-linejoin="round"
          stroke-miterlimit="2"
      >
        <path d="M0 7.501a1.21 1.21 0 0 1 .384-.895L6.866.352C7.097.126 7.394 0 7.748 0 8.459 0 9 .533 9 1.234a1.27 1.27 0 0 1-.36.87L3.044 7.5l5.581 5.383c.238.237.371.551.374.879v.005c0 .701-.541 1.233-1.252 1.233-.355 0-.652-.124-.885-.364L.383 8.393A1.22 1.22 0 0 1 0 7.501z" />
      </svg>
      <span>Európai nézet</span>
    </div>
      <accordion :open="true" class="accordion ep2024">
      <template #title>
       <span class="ov2024-list__title">{{ selectedMemberState.name }} <template v-if="selectedMemberStateData">{{ `(${selectedMemberStateData?.seatsTotal})` }}</template></span>
      </template>
      <template #content >
          <span class="ov2024-list__card__title">
            <template v-if="selectedMemberStateData">
              <span class="ov2024-list__subtitle">Eredmények</span>
                <div v-for="groupResult in selectedMemberStateData?.groupSummary?.groupDistribution">
                  <span class="ov2024-list__subtitle">
                     {{ groupResult.groupInfo.acronym }} {{ `(${groupResult.seatsTotal})` }}
                  </span>
                  <div :style="{'display': 'flex', 'flex-wrap': 'wrap'}">
                    <span v-for="index in groupResult.seatsTotal" class="ov2024-list__card__party__color" :style="{'background': groupResult.groupInfo.color}"/>
                  </div>
                </div>
                <div class="ov2024-list__card__party__item">
                  <span>Adatok állapota: {{ selectedMemberStateData.groupSummary.status }}</span>
                  <span>Utolsó frissítés: {{ selectedMemberStateData?.groupSummary?.time | formatDate }}</span>
                </div>
                <template v-if="selectedMemberStateData.turnout">
                  <span class="ov2024-list__subtitle">Részvétel</span>
                  <span>
                    {{ `${selectedMemberStateData.turnout.percent}%` }}
                  </span>
                  <div class="ov2024-list__card__party__item">
                    <span>Adatok állapota: {{ selectedMemberStateData.turnout.status }}</span>
                    <span>Utolsó frissítés: {{ selectedMemberStateData.turnout.time | formatDate }}</span>
                  </div>
                </template>
            </template>
            <div class="ov2024-list__card__party__item" v-html="selectedMemberStateDescription"/>
          </span>
      </template>
    </accordion>
    </div>
    <div v-else>
      <span class="ov2024-list__title">Tagállamok</span>
      <ul class="ov2024-list__highlight">
        <li v-for="memberState in memberStates" :key="memberState.name" style="cursor: pointer" class="ov2024-list__highlight__item" @click="navigateToMemberStateUrl(memberState)">
          <span>
            {{ memberState.name }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import electionApi from '@/api/ElectionApi'
import Accordion from '@/components/sub-components/Accordion'

export default {
  name: 'EpCountryResult',
  components: {
    Accordion,
  },
  props: {
    basePath: {
      type: String,
    },
  },
  data() {
    return {
      memberStates: [
        {
          name: 'Belgium',
          slug: 'be',
        },
        {
          name: 'Bulgária',
          slug: 'bg',
        },
        {
          name: 'Csehország',
          slug: 'cz',
        },
        {
          name: 'Ciprus',
          slug: 'cy',
        },
        {
          name: 'Dánia',
          slug: 'dk',
        },
        {
          name: 'Észtország',
          slug: 'ee',
        },
        {
          name: 'Finnország',
          slug: 'fi',
        },
        {
          name: 'Franciaország',
          slug: 'fr',
        },
        {
          name: 'Görögország',
          slug: 'el',
        },
        {
          name: 'Hollandia',
          slug: 'nl',
        },
        {
          name: 'Horvátország',
          slug: 'hr',
        },
        {
          name: 'Írország',
          slug: 'ie',
        },
        {
          name: 'Lengyelország',
          slug: 'pl',
        },
        {
          name: 'Lettország',
          slug: 'lv',
        },
        {
          name: 'Litvánia',
          slug: 'lt',
        },
        {
          name: 'Luxemburg',
          slug: 'lu',
        },
        {
          name: 'Magyarország',
          slug: 'hu',
        },
        {
          name: 'Málta',
          slug: 'mt',
        },
        {
          name: 'Németország',
          slug: 'de',
        },
        {
          name: 'Olaszország',
          slug: 'it',
        },
        {
          name: 'Portugália',
          slug: 'pt',
        },
        {
          name: 'Románia',
          slug: 'ro',
        },
        {
          name: 'Spanyolország',
          slug: 'es',
        },
        {
          name: 'Svédország',
          slug: 'se',
        },
        {
          name: 'Szlovákia',
          slug: 'sk',
        },
        {
          name: 'Szlovénia',
          slug: 'si',
        },
        {
          name: 'Ausztria',
          slug: 'at',
        },
      ],
      selectedMemberState: null,
      selectedMemberStateData: null,
      selectedMemberStateDescription: null,
      windowWidth: null,
    }
  },
  filters: {
    formatDate(value) {
      return value
      ? new Date(value).toLocaleString("hu-HU")
      : 'ismeretlen'
    },
  },
  computed: {
    getMemberStateName() {
      return this.selectedMemberState
        ? this.selectedMemberState.name
        : 'Tagállamok'
    },
  },
  watch: {
    $route: function(route) {
      if (route) {
        this.setMemberStateFromPath(route.path)
      }
    },
    // deep: true,
  },
  mounted() {
    this.setMemberStateFromPath(this.$router.currentRoute.path);
  },
  methods: {
    selectCountryCode(countryCode) {
      this.$emit('selectCountryCode', countryCode);
    },
    getCountryCodeFromPath(path) {
      const cleanedPath = path.charAt(path.length-1) === '/' ? path.slice(0, path.length-1) : path;
      if (cleanedPath.charAt(cleanedPath.length-3) === '/') {
        const countryCode = cleanedPath.slice(cleanedPath.length-2);
        return countryCode;
      } else {
        return null;
      }
    },
    async setMemberStateFromPath(path) {
      const countryCode = this.getCountryCodeFromPath(path);
      if (countryCode) {
        const memberState = this.getMemberStateByCode(countryCode);
        this.setSelectedMemberState(memberState);
      } else {
        console.warn('no country code in path')
        this.deselectMemberStates();
      }
    },
    async fetchMemberStateData(memberState) {
      const result = await (await electionApi.getEpNationalResult(memberState.slug, true)).json()
      const data = result && result.data
      return data;
    },
    getMemberStateByCode(countryCode) {
      const memberState = this.memberStates.find(memberState => memberState.slug === countryCode);
      return memberState;
    },
    cleanUpDescription(description) {
      return description.replace(/[\{\}]/g, "");
    },
    async setSelectedMemberState(memberState) {
      this.selectedMemberState = memberState;
      this.selectedMemberStateData = null;
      this.selectedMemberStateDescription = null;
      this.selectCountryCode(memberState.slug);
      // TODO change to a more surefire way (e.g. Promise.all to prevent possible mismatch between the three variables and actual selection due to slow responses)
      this.fetchMemberStateData(memberState).then(result => {
        if (result?.nationalResult?.length === 1) {
          this.selectedMemberStateData = result.nationalResult[0];
        } else if (result.nationalResult.length === 0) {
          console.warn(`No data for ${memberState.slug}`);
        } else {
          console.error(`Unexpected response for ${memberState.slug}`);
        }
      });
      electionApi.getEpNationalDescription().then(result => {
        result.json().then(json => {
          const uniques = json?.uniques;
          if (!uniques || !uniques.length || uniques.length !== 1) {
            console.error("Unexpected number of unique items!");
          } else {
            const content = uniques[0].content;
            const memberStateCodesAndDescriptions = JSON.parse(content);
            if (memberStateCodesAndDescriptions) {
              const memberStateCodesAndDescription = memberStateCodesAndDescriptions.find(memberStateCodesAndDescription => memberStateCodesAndDescription.key.toLowerCase() === this.selectedMemberState.slug.toLowerCase())
              if (!memberStateCodesAndDescription) {
                console.warn(`No description found for ${this.selectedMemberState.slug}`);
              } else {
                const cleanedDescription = this.cleanUpDescription(memberStateCodesAndDescription.content);
                this.selectedMemberStateDescription = cleanedDescription;
              }
            }
          }
        })
      })
      if (memberState.slug === 'hu') {
        const result = await (await electionApi.getEpHuResultMegyek()).json()
      }
    },
    navigateToMemberStateUrl(memberState) {
      this.$router.push(`${this.basePath}/${memberState.slug}`)
    },
    deselectMemberStates() {
      this.selectedMemberState = null;
      this.selectedMemberStateData = null;
      this.selectedMemberStateDescription = null;
      this.$emit('selectCountryCode', null);
    },
  },
}
</script>
<style scoped lang="scss">
.accordion.ep2024 {
  .ov2024-list__card__party__color {
    margin: 0 3px 3px 0;
  }
}

</style>
