<template>
  <div class="list__faq">
    <div class="list__faq__container" :class="{'list__faq__container__solo' : $route.fullPath === '/melleklet/ep-2024/frakciok'}">
      <div class="list__faq__content">
        <accordion
            v-for="(current, index) in faq.filter(f => f.t === 'u')"
            :key="index"
            class="accordion"
        >
          <template #title>
            <div class="accordion__title">
              <div v-html="current.q" />
            </div>
          </template>
          <template #content>
            <div class="accordion__content">
              <div v-html="current.a" />
            </div>
          </template>
        </accordion>
      </div>
      <div class="list__faq__content">
        <accordion
            v-for="(current, index) in faq.filter(f => f.t === 'd')"
            :key="index"
            class="accordion"
        >
          <template #title>
            <div class="accordion__title">
              <div v-html="current.q" />
            </div>
          </template>
          <template #content>
            <div class="accordion__content">
              <div v-html="current.a" />
            </div>
          </template>
        </accordion>
      </div>
    </div>
  </div>
</template>
<script>
import Accordion from '@/components/sub-components/Accordion'
import sizeReporter from '@/mixins/sizeReporter'

export default {
  name: 'EpFraction',
  components: { Accordion },
  mixins: [sizeReporter],
  computed: {
    faq() {
      return [
        { t: 'u', q: 'Európai Néppárt', a: 'Jobbközép pártcsalád, de vannak benne liberálisabb és konzervatívabb pártok is. Évtizedek óta az Európai Parlament legnagyobb frakcióját adja. A felmérések alapján ez nem fog változni az idei választás után sem. A súlyának megfelelően a csoport adta a 2019-2024 közötti ciklusban többek között az Európai Bizottság vezetőjét, Ursula von der Leyent is. A néppártiak a mostani választásra is <a href="/kulfold/2024/03/07/ursula-von-der-leyen-europai-bizottsag-elnok-neppart-parlament-mezogazdasag-gazdatuntetesek-vedelempolitika-ipar">őt jelölték</a>, hogy folytassa a munkáját, ha győznek. Magyarországról csak a KDNP a csoport tagja, miután a Fidesz 2021-ben <a href="/kulfold/2021/03/03/fidesz-europai-neppart-epp-kilepes-frakcio-orban-weber-szabalyzat-modositas">kilépett</a> a felfüggesztése felé haladó frakcióból és a pártcsaládból is. Itt <a href="https://444.hu/2024/05/12/a-tisza-part-mellett-a-jobbik-es-a-megoldas-mozgalom-is-az-europai-neppart-frakciojaba-ulne-be" target="_blank">szeretne kikötni</a> a Tisza Párt is, de a felvétele nem automatikus, ahogy azt a már évekkel ezelőtt csatlakozni akaró Mindenki Magyarországa Mozgalom és a Jobbik példája is mutatja.' },
        { t: 'u', q: 'S&D', a: 'A szocialistáké az Európai Parlament második legnagyobb frakciója. A csúcsjelöltjük Nicolas Schmit, az Európai Bizottság szociális ügyekért felelős tagja, aki <a href="/kulfold/2024/04/15/eu-schmit-szocialistak-jogallamisag-katargate">Magyarországon is járt</a> a kampány alatt. A csoport több, azóta kizárt tagja érintett volt a 2022 végén kirobbant <a href="/kulfold/2022/12/15/eva-kaili-korrupcio-katar-katar-gate-europai-parlament">katari vesztegetési botrányban</a>, de a felmérések alapján várhatóan csak kisebb veszteségekre számíthat a választáson. Most általában a néppártiakkal és a liberálisokkal alkotott „nagykoalíciója” dönti el az európai parlamenti szavazásokat. A csoport <a href="/kulfold/2024/05/23/csucsjeloltek-vita-europai-parlament-von-der-leyen-schmit-gozi-reintke-baier">előre figyelmeztette</a> a jobbközép Európai Néppártot, hogy nem működik együtt vele, ha nyit az euroszkeptikus ECR felé. Schmit <a href="/kulfold/2024/05/23/csucsjeloltek-vita-europai-parlament-von-der-leyen-schmit-gozi-reintke-baier">kizárta</a> az összefogást az euroszkeptikusokkal és a szélsőjobboldali ID-vel. A szocialistákhoz tartozik a Demokratikus Koalíció és a vele egy listán induló MSZP is.' },
        { t: 'u', q: 'Renew', a: 'A liberálisoké volt 2019 és 2024 között a harmadik legnagyobb frakció, de a felmérések alapján ők veszíthetik a legtöbb képviselőt a júniusi választáson. Az Európa Megújul eddig sokféle kombinációban szavazhatott együtt sikeresen a többi párttal. Akár a néppártiakkal és a szocialistákkal a hagyományos európai parlamenti nagykoalícióban, akár a baloldaliakkal, akár - például <a href="/kulfold/2023/11/09/europai-parlament-euro-7-elektromos-auto">környezetvédelmi ügyekben</a> - az Európai Néppárttal és az ECR-rel is többséget alakíthatott ki. A szélsőségesebb jobboldali pártok erősödésével és a saját gyengülésével ez változhat. Az EP-választás után több intézmény vezetőjét váltják, köztük az Európai Tanácsét, ahol eddig a liberális Charles Michel volt az elnök, de a szabályok szerint nem maradhat ott tovább. A frakció három pártcsaládja összesen nem egy, hanem három csúcsjelöltet nevezett meg. Az EP-választáson induló magyar pártok közül a Momentum a tagja.' },
        { t: 'u', q: 'ECR', a: 'Az Európai Konzervatívok és Reformerek csoportját részben a jobbközép Európai Néppártból korábban kilépő brit konzeratívok alapították, hogy euroszkeptikusabb irányba vigyék az EU-t, mielőtt az Egyesült Királyság végül kilépett volna. A Fidesz is itt kötött ki a néppárti távozása után az uniós Régiók Tanácsában és az EU-tól független Európa Tanács közgyűlésében, de az Európai Parlamentben nem, és a frakció mellett a júniusi választásig a pártcsaládba se lépett be. A nagyobbik magyar kormánypártból sok politikus, köztük Orbán Viktor beszélt arról, hogy csatlakozna. Több ECR-es képviselő viszont <a href="/kulfold/2024/02/09/ecr-europai-parlament-fidesz-oroszorszag-ukrajna">ódzkodott</a> ettől vagy kifejezetten <a href="/kulfold/2024/06/04/ecr-fidesz-europai-parlament-partcsalad-finnorszag-riikka-purra">ellenezte</a> ezt - főleg Ukrajna és Oroszország megítélése miatt. Az EP-választás után dől majd el, hogy a Fidesz beléphet-e. Várhatóan még a Fidesz nélkül is az Európai Konzervatívok és Reformerek növelhetik leginkább a képviselői helyeik számát. A választás előtt a szélsőjobboldali ID-ből a francia Nemzeti Tömörülés vezetője, Marine Le Pen az ECR-frakció mostani legnagyobb küldöttségét adó Olasz Testvérek és az ECR-pártcsalád elnökét, Giorgia Melonit győzködte egy közös csoportról. Korábban magyar kormánypárti politikusok is szorosabb együttműködést pedzegettek a jobboldali pártok között, és még a néppárti Ursula von der Leyen sem zárta ki az együttműködést az ECR-rel.' },
        { t: 'u', q: 'ID', a: 'Az euroszkeptikus ECR mellett a szélsőjobboldali Identitás és Demokrácia tűnt az egyik legnagyobb nyertesnek a felmérések alapján. A csoport viszont megelégelte az Alternatíva Németországért körüli, kínai és orosz befolyás miatt indított nyomozásokat, valamint a tagjai szélsőséges megnyilvánulásait, így kizárta a pártot. Az ID még így is a nyertesek közé kerülhet, de a legnagyobb tagja, a francia Nemzeti Tömörülés az ECR-es Olasz Testvérek felé kacsintgat. A két frakció között az egyik legnagyobb eltérés Ukrajna és Oroszország megítélése, ami az ukránbarát ECR-rel szemben vonzóbbá is tehetné a Fidesznek az ID-t. A csoportot ugyanakkor szinte teljesen kihagyják a fontosabb európai parlamenti helyekből, például az ECR-rel és a kisebb Baloldallal szemben alelnöke sincs, és egy-egy jogszabály parlamenti felelősének (jelentéstevőjének) is csak ritkán nevezik ki a tagjait. Az ID ezen az elszigeteltségen változtathat egy jó szerepléssel. Az AfD kizárása előtt Toroczkai László <a href="/belfold/2024/05/17/toroczkai-laszlo-mi-hazank-mozgalom-ep-valasztas-listavezeto-interju">arról beszélt</a> a Telexnek, hogy a Mi Hazánk az ID-hez <a href="/kulfold/2024/04/18/eu-ep-mkkp-zoldek">csatlakozna</a>, a távozás óta viszont már új frakciót <a href="/belfold/2024/05/30/budapestre-latogat-az-afd-listavezetoje-hogy-toroczkaival-targyaljon-egy-uj-europai-frakciorol-maximilian-krah-mi-hazank">alapítana</a> a kizárt német párttal.' },
        { t: 'u', q: 'Zöldek/EFA', a: 'A liberálisok mellett a zöldek veszíthetnek sok európai parlamenti helyet a felmérések alapján. A csoport derekát a legtöbb képviselőt adó Németországban erős tagpártjuk adja, de a koalíciós kormányzás megtépázta a népszerűségüket, uniós társaik pedig többek között a gazdatüntetések miatt váltak célponttá. Társ-csúcsjelöltjeit Terry Reintke és Bas Eickhout. A frakcióba valójában nem csak “a” zöld pártcsalád küld képviselőket. A parlamenti csoport nevében is szereplő Európai Szabad Szövetség (EFA) nagyobb helyi önállóságot követelő régiók képviselőiből áll, és az Európai Kalózpárt is ebben a közös frakcióban vesz részt. Utóbbihoz csatlakozna a Magyar Kétfarkú Kutya Párt, így kerülne egy csoportba az LMP-vel, amivel a zöldek a választás végéig megszüntették az együttműködést. A vita amiatt robbant ki, hogy a teljes jogú magyar tagpártjuk beállt Vitézy Dávid mögé a zöldekkel társult Párbeszéd jelöltjével, Karácsony Gergellyel szemben.' },
        { t: 'u', q: 'Baloldal', a: 'A frakciók közül a szélsőbaloldaliaké a legkisebb és ez szenvedheti el a legnagyobb arányú veszteséget a közvélemény-kutatások alapján. A Baloldalt részben a jobboldali szélsőségesek előretörése sújtja. Például a legtöbb képviselőt adó uniós tagállamban az Alternatíva Németországnak szívott el rengeteg szavazatot az egykori keletnémet állampárt utódjától, a Baloldaltól (Die Linke). Az viszont külön lehúzza a szélsőbaloldaliak várható teljesítményét, hogy a Linkéből kilépett az egyik legnépszerűbb politikusa, <a href="/kulfold/2024/06/06/europai-parlamenti-valasztas-nemetorszag-franciaorszag-olaszorszag-spanyolorszag-lengyelorszag">Sahra Wagenknecht</a>, aki a politikai paletta másik végéről is átvett jelszavakkal terelt át magához sok szavazót. A csoport csúcsjelöltje, Walter Baier többek között az inflációval, a lakhatási költségekkel és a Fideszhez hasonlóan azonnali ukrajnai tűzszünettel kampányolt, de a tagpártok a felmérések alapján nem kaptak olyan lendületet, mint amilyet a 2008-ban indult gazdasági válság adott neki.' },
        { t: 'u', q: 'Függetlenek', a: 'Ide kerülnek azok a pártok és képviselők, akik valamiért egyetlen frakciónak sem tagjai. Általában az az ok, hogy egyikükkel sem tudnak ideológiailag azonosulni, mindenkinek túl szélsőségesek vagy a pártcsaládjuknak nincs elég EP-képviselője a frakcióalapításhoz. Utóbbi kategóriába esik a Jobbik, a Fidesz pedig a 2021-es néppárti szakítás óta tartozik ide annak ellenére, hogy például az uniós Régiók Tanácsában már az ECR-esek között ülnek a képviselői. Olyan szélsőjobboldali pártok is itt vannak, amelyek az ID-nek is elfogadhatatlanok, mint a nemrég kizárt Alternatíva Németországnak, amely önálló frakciót alapítana. A függetlenek közé került például Görögország Kommunista Pártja, ami a Baloldalnál is baloldalibb, az olasz populista Öt Csillag Mozgalom és a német A PÁRT viccpárt is. Ez komoly hátrányt jelent: frakció nélkül alig jutnak felszólalási lehetőséghez, nincs mögöttük olyan kapcsolati háló és közös titkárság, ami segítene nekik, az olyan posztokról pedig nem is érdemes beszélni, mint az EP vagy akár csak egy szakbizottság alelnöksége.' },
        { t: 'u', q: 'Újoncok', a: 'Itt tartják nyilván azokat a pártokat, amelyek újként érkeznek az Európai Parlamentbe és még nem tudni, kihez csatlakoznának. Az EP az általa szolgáltatott adatok szerint azokat sorolja ide, akik egyik frakciónak vagy pártcsaládnak sem tagjai. Várhatóan a legnagyobb ilyen párt a német szélsőbaloldalból kivált Sahra Wagenknecht Szövetség, ami részben a szélsőjobboldaltól vett át jelszavakat. Ebbe a kategóriába kerülhet elsőként többek között a Mi Hazánk és a Tisza Párt is.' },
      ]
    },
  },
}
</script>
